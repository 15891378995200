import React, { useState,useEffect } from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ArrowUpRight, ArrowDownRight, DollarSign, Users, ShoppingBag, Activity } from 'lucide-react';
import './Dashboard.css';
import { API_ENDPOINTS } from '../../util/apiConfig';
import axios from 'axios';
const Dashboard = () => {
     
 const  [Dashboard,setDashBoard]=useState([]);

    useEffect(() => {
      const fetchDashBoard=async ()=>{
             const response=await axios.get(API_ENDPOINTS.DASHBOARD);
             setDashBoard(response.data)
      }
      fetchDashBoard();
    },[]);

  // Sample data for charts
  const salesData = [
    { month: 'Jan', sales: 4000 },
    { month: 'Feb', sales: 3000 },
    { month: 'Mar', sales: 5000 },
    { month: 'Apr', sales: 4500 },
    { month: 'May', sales: 6000 },
    { month: 'Jun', sales: 5500 },
  ];

  const revenueData = [
    { name: 'Mon', revenue: 2400 },
    { name: 'Tue', revenue: 1398 },
    { name: 'Wed', revenue: 9800 },
    { name: 'Thu', revenue: 3908 },
    { name: 'Fri', revenue: 4800 },
    { name: 'Sat', revenue: 3800 },
    { name: 'Sun', revenue: 4300 },
  ];

  const productData = [
    { name: 'Electronics', value: 400 },
    { name: 'Clothing', value: 300 },
    { name: 'Books', value: 300 },
    { name: 'Home', value: 200 },
  ];

  return (
    <div className="dashboard">
      {/* Stats Grid */}
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#e0f2fe' }}>
            <DollarSign size={24} color="#0284c7" />
          </div>
          <div className="stat-info">
            <h3>Total Revenue</h3>
            <div className="stat-value">${Dashboard[0]?.number}</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>{Dashboard[0]?.percentage}%</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#dcfce7' }}>
            <Users size={24} color="#16a34a" />
          </div>
          <div className="stat-info">
            <h3>Total Users</h3>
            <div className="stat-value">{Dashboard[1]?.number}</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>{Dashboard[1]?.percentage}%</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#fef3c7' }}>
            <ShoppingBag size={24} color="#d97706" />
          </div>
          <div className="stat-info">
            <h3>Total Orders</h3>
            <div className="stat-value">{Dashboard[2]?.number}</div>
            <div className="stat-change negative">
              <ArrowDownRight size={16} />
              <span>{Dashboard[2]?.percentage}%</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#ffe4e6' }}>
            <Activity size={24} color="#e11d48" />
          </div>
          <div className="stat-info">
            <h3>User visited</h3>
            <div className="stat-value">{Dashboard[3]?.number}</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>{Dashboard[3]?.percentage}%</span>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="charts-grid">
        {/* Sales Chart */}
        <div className="chart-card">
          <h3>Monthly Sales</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#3b82f6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Revenue Chart */}
        <div className="chart-card">
          <h3>Weekly Revenue</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="revenue" stroke="#3b82f6" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Product Distribution */}
        <div className="chart-card">
          <h3>Product Distribution</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={productData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#3b82f6"
                  dataKey="value"
                  label
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;