import React, { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon, PlusIcon, XIcon, CheckIcon } from 'lucide-react';
import './SubscriptionManager.css';
import { API_ENDPOINTS_AUTH } from '../../util/apiConfig';
import axios from 'axios';
const SubscriptionManager = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  
  const [editingId, setEditingId] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newSubscription, setNewSubscription] = useState({
    name: '',
    description: '',
    price: '',
    points: ['']
  });
  
  useEffect(()=>{
    const fetchPlan=()=>{
     const data=axios.get(API_ENDPOINTS_AUTH.PLAN).then((response)=>{
        setSubscriptions(response.data)
     })
    }
    fetchPlan();
  },[])

  const handleEdit = (subscription) => {
    setEditingId(subscription.id);
    setNewSubscription({ ...subscription });
  };

  const handleDelete = (id) => {
    setSubscriptions(subscriptions.filter(sub => sub.id !== id));
  };

  const handleSave = () => {
    if (editingId) {
      setSubscriptions(subscriptions.map(sub =>
        sub.id === editingId ? { ...newSubscription, id: editingId } : sub
      ));
      setEditingId(null);
    } else {
      setSubscriptions([...subscriptions, {
        ...newSubscription,
        id: subscriptions.length + 1,
        price: parseFloat(newSubscription.price)
      }]);
      setIsAddingNew(false);
    }
    setNewSubscription({ name: '', description: '', price: '', points: [''] });
  };

  const handleAddPoint = () => {
    setNewSubscription({
      ...newSubscription,
      points: [...newSubscription.points, '']
    });
  };

  const handlePointChange = (index, value) => {
    const newPoints = [...newSubscription.points];
    newPoints[index] = value;
    setNewSubscription({ ...newSubscription, points: newPoints });
  };

  const handleRemovePoint = (index) => {
    const newPoints = newSubscription.points.filter((_, i) => i !== index);
    setNewSubscription({ ...newSubscription, points: newPoints });
  };

  const SubscriptionForm = () => (
    <div className="subscription-form">
      <input
        type="text"
        placeholder="Subscription Name"
        value={newSubscription.name}
        onChange={(e) => setNewSubscription({ ...newSubscription, name: e.target.value })}
        className="form-input"
      />
      <textarea
        placeholder="Description"
        value={newSubscription.description}
        onChange={(e) => setNewSubscription({ ...newSubscription, description: e.target.value })}
        className="form-textarea"
      />
      <input
        type="number"
        placeholder="Price"
        value={newSubscription.price}
        onChange={(e) => setNewSubscription({ ...newSubscription, price: e.target.value })}
        className="form-input"
      />
      <div className="points-container">
        {newSubscription.points.map((point, index) => (
          <div key={index} className="point-input-group">
            <input
              type="text"
              placeholder="Feature point"
              value={point}
              onChange={(e) => handlePointChange(index, e.target.value)}
              className="point-input"
            />
            <button
              onClick={() => handleRemovePoint(index)}
              className="btn btn-icon btn-danger"
            >
              <XIcon className="icon-small" />
            </button>
          </div>
        ))}
      </div>
      <div className="form-actions">
        <button onClick={handleAddPoint} className="btn btn-outline">
          <PlusIcon className="icon-small" /> Add Point
        </button>
        <button onClick={handleSave} className="btn btn-primary">
          <CheckIcon className="icon-small" /> Save
        </button>
        <button 
          onClick={() => {
            setEditingId(null);
            setIsAddingNew(false);
            setNewSubscription({ name: '', description: '', price: '', points: [''] });
          }}
          className="btn btn-danger"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="subscription-container">
      <div className="header">
        <h1 className="title"></h1>
        {!isAddingNew && !editingId && (
          <button onClick={() => setIsAddingNew(true)} className="btn btn-primary">
            <PlusIcon className="icon-small" /> Add New Plan
          </button>
        )}
      </div>

      {(isAddingNew || editingId) && <SubscriptionForm />}

      <div className="subscription-grid">
        {subscriptions.map(subscription => (
          <div key={subscription.id} className={`subscription-card ${editingId === subscription.id ? 'editing' : ''}`}>
            <div className="card-content">
              <div className="card-header">
                <div>
                  <h2 className="card-title">{subscription.name}</h2>
                  <p className="card-description">{subscription.description}</p>
                </div>
                <div className="card-price">${subscription.price}</div>
              </div>
              
              <ul className="points-list">
                  <li  className="point-item">
                    <div className="point-bullet"></div>
                    {subscription.points} Reward Points
                  </li>
              </ul>

              {!editingId && !isAddingNew && (
                <div className="card-actions">
                  <button
                    onClick={() => handleEdit(subscription)}
                    className="btn btn-icon btn-outline"
                  >
                    <PencilIcon className="icon-small" />
                  </button>
                  <button
                    onClick={() => handleDelete(subscription.id)}
                    className="btn btn-icon btn-danger"
                  >
                    <TrashIcon className="icon-small" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionManager;