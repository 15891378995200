import React, { useState, useEffect } from 'react';
import './BrandCollectionManager.css'
import ImageUploadSection from './ImageUploadSection';
import axios from 'axios';
import { API_ENDPOINTS } from '../util/apiConfig';

const BrandCollectionManager = () => {
    // State management
    const [collections, setCollections] = useState([]);
    const [brands, setBrands] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [activeTab, setActiveTab] = useState("collections");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [isNewItem, setIsNewItem] = useState(false);
    const [currentLanguages, setCurrentLanguages] = useState({ en: true, jp: false, cn: false, tw: false, fr: false, es: false });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeLanguageTab, setActiveLanguageTab] = useState("en");
    const [formData, setFormData] = useState({});

    // Languages configuration
    const languages = [
        { code: 'en', name: 'English', default: true },
        { code: 'jp', name: 'Japanese' },
        { code: 'cn', name: 'Chinese' },
        { code: 'tw', name: 'Taiwanese' },
        { code: 'fr', name: 'French' },
        { code: 'es', name: 'Spanish' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseBrand = await axios.get(API_ENDPOINTS.BRANDS);
                setBrands(responseBrand.data);
                
                const responseCollection = await axios.get(API_ENDPOINTS.COLLECTION);
                setCollections(responseCollection.data);
                
                const responseOccasion = await axios.get(API_ENDPOINTS.OCCATIONS);
                setOccasions(responseOccasion.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                showErrorMessage("Failed to load data. Please try again.");
            }
        };
        fetchData();
    }, []);
    
    // Set up language checkboxes based on available translations
    useEffect(() => {
        if (editItem && editItem.translations && Array.isArray(editItem.translations)) {
            // Create a new language state object starting with English always enabled
            const newLanguageState = { en: true };
            
            // Check which languages exist in the translations
            editItem.translations.forEach(translation => {
                if (translation.languageCode && translation.languageCode !== 'en') {
                    newLanguageState[translation.languageCode] = true;
                }
            });
            
            // For languages not in the translations, set them to false
            languages.forEach(lang => {
                if (lang.code !== 'en' && newLanguageState[lang.code] === undefined) {
                    newLanguageState[lang.code] = false;
                }
            });
            
            setCurrentLanguages(newLanguageState);
            
            // Set active language tab to the first available language
            const firstAvailableLanguage = Object.keys(newLanguageState).find(lang => newLanguageState[lang]);
            if (firstAvailableLanguage) {
                setActiveLanguageTab(firstAvailableLanguage);
            }
        }
    }, [editItem]);
    
    const handleAddItem = () => {
        setIsNewItem(true);
        let newItem;
        
        if (activeTab === "collections") {
            newItem = {
                imageUrl: null,
                translations: [{
                    collectionName: "",
                    description: "",
                    languageCode: "en"
                }]
            };
        } else if (activeTab === "brands") {
            newItem = {
                imageUrl: null,
                translations: [{
                    brandName: "",
                    languageCode: "en"
                }]
            };
        } else if (activeTab === "occasions") {
            newItem = {
                imageUrl: null,
                translations: [{
                    occasionName: "",
                    languageCode: "en"
                }]
            };
        }

        // Reset language checkboxes to default (only English enabled)
        setCurrentLanguages({ en: true, ja: false, zh: false, tw: false, fr: false, es: false });
        setActiveLanguageTab("en");
        
        setEditItem(newItem);
        setFormData(newItem);
        setIsDialogOpen(true);
    };

    const handleEditItem = (item, index) => {
        setIsNewItem(false);
        const itemWithIndex = { ...item, index };
        setEditItem(itemWithIndex);
        setFormData(itemWithIndex);
        setIsDialogOpen(true);
    };

    const handleDeleteItem = async (obj, index) => {
        try {
            let endpoint;
            let payload=new FormData();
            obj.action='D'
            if (activeTab === "collections") {
                endpoint = `${API_ENDPOINTS.COLLECTION}`;
                payload.append('collectionCatelogue', new Blob([JSON.stringify(obj)], {
                    type: 'application/json'
                  }));
                  payload.append('file', new Blob([], {
                    type: 'application/json'
                  }));
                await axios.post(endpoint,payload);
                setCollections(collections.filter(item => item.id !== obj.id));
                showSuccessMessage("Collection deleted successfully");
            } else if (activeTab === "brands") {
                payload.append('brands', new Blob([JSON.stringify(obj)], {
                    type: 'application/json'
                  }));
                  payload.append('file', new Blob([], {
                    type: 'application/json'
                  }));
                await axios.post(endpoint,payload);
                endpoint = `${API_ENDPOINTS.BRANDS}`;
                await axios.post(endpoint,payload);
                setBrands(brands.filter(item => item.id !== obj.id));
                showSuccessMessage("Brand deleted successfully");
            } else if (activeTab === "occasions") {
                payload.append('occations', new Blob([JSON.stringify(obj)], {
                    type: 'application/json'
                  }));
                  payload.append('file', new Blob([], {
                    type: 'application/json'
                  }));
                endpoint = `${API_ENDPOINTS.OCCATIONS}`;
                await axios.post(endpoint,payload);
                setOccasions(occasions.filter(item => item.id !== obj.id));
                showSuccessMessage("Occasion deleted successfully");
            }
        } catch (error) {
            console.error("Error deleting item:", error);
            showErrorMessage("Failed to delete item. Please try again.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        // Create a deep copy of formData
        const updatedFormData = { ...formData };
        
        // Find or create translation for the current language
        if (!updatedFormData.translations) {
            updatedFormData.translations = [];
        }
        
        // Find index of the translation for current language
        const langIndex = updatedFormData.translations.findIndex(
            t => t.languageCode === activeLanguageTab
        );
        
        if (langIndex >= 0) {
            // Update existing translation
            updatedFormData.translations[langIndex] = {
                ...updatedFormData.translations[langIndex],
                [name]: value
            };
        } else {
            // Create new translation
            const newTranslation = { languageCode: activeLanguageTab, [name]: value };
            updatedFormData.translations.push(newTranslation);
        }
        
        setFormData(updatedFormData);
    };

    const handleLanguageChange = (e) => {
        const { name, checked } = e.target;
        setCurrentLanguages({ ...currentLanguages, [name]: checked });
        
        // If user unchecks the current active language tab, switch to another enabled language
        if (!checked && name === activeLanguageTab) {
            const nextLanguage = Object.keys(currentLanguages).find(lang => 
                lang !== name && (lang === 'en' || currentLanguages[lang])
            );
            if (nextLanguage) {
                setActiveLanguageTab(nextLanguage);
            }
        }
        
        // If a language is checked and there's no translation for it yet, add an empty one
        if (checked) {
            const updatedFormData = { ...formData };
            
            if (!updatedFormData.translations) {
                updatedFormData.translations = [];
            }
            
            // Check if translation already exists
            const exists = updatedFormData.translations.some(t => t.languageCode === name);
            
            if (!exists) {
                // Create new translation based on active tab
                let newTranslation = { languageCode: name };
                
                if (activeTab === "collections") {
                    newTranslation.collectionName = "";
                    newTranslation.description = "";
                } else if (activeTab === "brands") {
                    newTranslation.brandName = "";
                } else if (activeTab === "occasions") {
                    newTranslation.occasionName = "";
                }
                
                updatedFormData.translations.push(newTranslation);
                setFormData(updatedFormData);
            }
        } else if (!checked && name !== 'en') {
            // If unchecking a language, remove its translation
            const updatedFormData = { ...formData };
            
            if (updatedFormData.translations) {
                updatedFormData.translations = updatedFormData.translations.filter(
                    t => t.languageCode !== name
                );
                setFormData(updatedFormData);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            let endpoint;
            let payload= new FormData();
            formData.action='I'              
            if (formData.file) {
                payload.append('file', formData.file);
            } else {
                payload.append('file', new Blob([]));
            }
            
            // Configure axios to handle FormData
            const config = {
                headers: {
                    'Content-Type': formData.file ? 'multipart/form-data' : 'application/json'
                }
            };
            if (isNewItem) {
                if (activeTab === "collections") {
                    payload.append('collectionCatelogue', new Blob([JSON.stringify(formData)], {
                        type: 'application/json'
                      }));
                    endpoint = API_ENDPOINTS.COLLECTION;
                    const response = await axios.post(endpoint, payload, config);
                    setCollections([...collections, response.data]);
                    showSuccessMessage("Collection added successfully");
                } else if (activeTab === "brands") {
                    payload.append('brands', new Blob([JSON.stringify(formData)], {
                        type: 'application/json'
                      }));
                    endpoint = API_ENDPOINTS.BRANDS;
                    const response = await axios.post(endpoint, payload, config);
                    setBrands([...brands, response.data]);
                    showSuccessMessage("Brand added successfully");
                } else if (activeTab === "occasions") {
                    payload.append('occations', new Blob([JSON.stringify(formData)], {
                        type: 'application/json'
                      }));
                    endpoint = API_ENDPOINTS.OCCATIONS;
                    const response = await axios.post(endpoint, payload, config);
                    setOccasions([...occasions, response.data]);
                    showSuccessMessage("Occasion added successfully");
                }
            } else {                
                const id = formData.id;
                if (activeTab === "collections") {
                    endpoint = `${API_ENDPOINTS.COLLECTION}`;
                    await axios.post(endpoint, payload, config);
                    const updatedCollections = collections.map(item => 
                        item.id === id ? { ...item, ...formData } : item
                    );
                    setCollections(updatedCollections);
                    showSuccessMessage("Collection updated successfully");
                } else if (activeTab === "brands") {
                    endpoint = `${API_ENDPOINTS.BRANDS}`;
                    await axios.post(endpoint, payload, config);
                    const updatedBrands = brands.map(item => 
                        item.id === id ? { ...item, ...formData } : item
                    );
                    setBrands(updatedBrands);
                    showSuccessMessage("Brand updated successfully");
                } else if (activeTab === "occasions") {
                    endpoint = `${API_ENDPOINTS.OCCATIONS}`;
                    await axios.post(endpoint, payload, config);
                    const updatedOccasions = occasions.map(item => 
                        item.id === id ? { ...item, ...formData } : item
                    );
                    setOccasions(updatedOccasions);
                    showSuccessMessage("Occasion updated successfully");
                }
            }
            
            setIsDialogOpen(false);
            setFormData({});
        } catch (error) {
            console.error("Error submitting form:", error);
            showErrorMessage("Failed to save changes. Please try again.");
        }
    };

    const showSuccessMessage = (message) => {
        setSuccessMessage(message);
        setTimeout(() => setSuccessMessage(""), 3000);
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);
        setTimeout(() => setErrorMessage(""), 3000);
    };

    // Helper function to get translation for the specific language
    const getTranslation = (translations, langCode, field) => {
        if (!translations || !Array.isArray(translations)) return "";
        
        const translation = translations.find(t => t.languageCode === langCode);
        return translation ? translation[field] || "" : "";
    };

    // Helper function to get display name based on active tab
    const getDisplayName = (item) => {
        if (!item.translations || !Array.isArray(item.translations) || item.translations.length === 0) {
            return "No name";
        }
        
        if (activeTab === "collections") {
            return getTranslation(item.translations, "en", "collectionName");
        } else if (activeTab === "brands") {
            return getTranslation(item.translations, "en", "brandName");
        } else {
            return getTranslation(item.translations, "en", "occationName");
        }
    };

    // Helper function to get field name based on active tab
    const getFieldName = () => {
        if (activeTab === "collections") {
            return "collectionName";
        } else if (activeTab === "brands") {
            return "brandName";
        } else {
            return "occasionName";
        }
    };

    return (
        <div className="container">
            {/* Success message */}
            {successMessage && (
                <div className="notification success-notification">
                    <span className="notification-icon">✓</span>
                    <span>{successMessage}</span>
                </div>
            )}

            {/* Error message */}
            {errorMessage && (
                <div className="notification error-notification">
                    <span className="notification-icon">!</span>
                    <span>{errorMessage}</span>
                </div>
            )}

            {/* Tabs */}
            <div className="tabs">
                <button
                    className={`tab-button ${activeTab === "collections" ? "active" : ""}`}
                    onClick={() => setActiveTab("collections")}
                >
                    Collections
                </button>
                <button
                    className={`tab-button ${activeTab === "brands" ? "active" : ""}`}
                    onClick={() => setActiveTab("brands")}
                >
                    Brands
                </button>
                <button
                    className={`tab-button ${activeTab === "occasions" ? "active" : ""}`}
                    onClick={() => setActiveTab("occasions")}
                >
                    Occasions
                </button>
            </div>

            {/* Collections Tab */}
            {activeTab === "collections" && (
                <div className="tab-content">
                    <div className="tab-header">
                        <h2 className="tab-title">Collections Management</h2>
                        <button className="add-button" onClick={handleAddItem}>+ Add Collection</button>
                    </div>

                    <div className="card-grid">
                        {collections.map((collection, index) => (
                            <div key={collection.id || index} className="card">
                                <div className="image-container">
                                    {collection.imageUrl ? (
                                        <img
                                            src={collection.imageUrl}
                                            alt={getDisplayName(collection)}
                                            className="card-image"
                                        />
                                    ) : (
                                        <div className="no-image">No image available</div>
                                    )}
                                </div>
                                <div className="card-header">
                                    <h3 className="card-title">{getDisplayName(collection)}</h3>
                                </div>
                                <div className="card-content">
                                    <p className="card-description">
                                        {getTranslation(collection.translations, "en", "description")}
                                    </p>
                                    <div className="card-actions">
                                        <button className="action-button edit-button" onClick={() => handleEditItem(collection, index)}>
                                            Edit
                                        </button>
                                        <button className="action-button delete-button" onClick={() => handleDeleteItem(collection, index)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Brands Tab */}
            {activeTab === "brands" && (
                <div className="tab-content">
                    <div className="tab-header">
                        <h2 className="tab-title">Brands Management</h2>
                        <button className="add-button" onClick={handleAddItem}>+ Add Brand</button>
                    </div>

                    <div className="card-grid">
                        {brands.map((brand, index) => (
                            <div key={brand.id || index} className="card">
                                <div className="image-container">
                                    {brand.imageUrl ? (
                                        <img
                                            src={brand.imageUrl}
                                            alt={getDisplayName(brand)}
                                            className="card-image"
                                        />
                                    ) : (
                                        <div className="no-image">No image available</div>
                                    )}
                                </div>
                                <div className="card-header">
                                    <h3 className="card-title">{getDisplayName(brand)}</h3>
                                </div>
                                <div className="card-content">
                                    <div className="card-actions">
                                        <button className="action-button edit-button" onClick={() => handleEditItem(brand, index)}>
                                            Edit
                                        </button>
                                        <button className="action-button delete-button" onClick={() => handleDeleteItem(brand, index)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Occasions Tab */}
            {activeTab === "occasions" && (
                <div className="tab-content">
                    <div className="tab-header">
                        <h2 className="tab-title">Occasions Management</h2>
                        <button className="add-button" onClick={handleAddItem}>+ Add Occasion</button>
                    </div>

                    <div className="card-grid">
                        {occasions.map((occasion, index) => (
                            <div key={occasion.id || index} className="card">
                                <div className="image-container">
                                    {occasion.imageUrl ? (
                                        <img
                                            src={occasion.imageUrl}
                                            alt={getDisplayName(occasion)}
                                            className="card-image"
                                        />
                                    ) : (
                                        <div className="no-image">No image available</div>
                                    )}
                                </div>
                                <div className="card-header">
                                    <h3 className="card-title">{getDisplayName(occasion)}</h3>
                                </div>
                                <div className="card-content">
                                    <div className="card-actions">
                                        <button className="action-button edit-button" onClick={() => handleEditItem(occasion, index)}>
                                            Edit
                                        </button>
                                        <button className="action-button delete-button" onClick={() => handleDeleteItem(occasion, index)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Edit/Add Dialog */}
            {isDialogOpen && (
                <div className="dialog-overlay">
                    <div className="dialog">
                        <div className="dialog-header">
                            <h2 className="dialog-title">
                                {isNewItem 
                                    ? `Add New ${activeTab === "collections" ? "Collection" : activeTab === "brands" ? "Brand" : "Occasion"}` 
                                    : `Edit ${activeTab === "collections" ? "Collection" : activeTab === "brands" ? "Brand" : "Occasion"}`}
                            </h2>
                            <button className="close-button" onClick={() => setIsDialogOpen(false)}>×</button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="dialog-content">
                                {/* Language selection checkboxes */}
                                <div className="language-checkboxes">
                                    <div className="checkbox-group">
                                        <input
                                            type="checkbox"
                                            id="en"
                                            name="en"
                                            checked
                                            disabled
                                        />
                                        <label htmlFor="en">English (Default)</label>
                                    </div>

                                    {languages.filter(lang => !lang.default).map(lang => (
                                        <div key={lang.code} className="checkbox-group">
                                            <input
                                                type="checkbox"
                                                id={lang.code}
                                                name={lang.code}
                                                checked={currentLanguages[lang.code]}
                                                onChange={handleLanguageChange}
                                            />
                                            <label htmlFor={lang.code}>{lang.name}</label>
                                        </div>
                                    ))}
                                </div>

                                {/* Image Upload */}
                                <ImageUploadSection
                                    currentImage={typeof formData.imageUrl === 'string' ? formData.imageUrl : ''}
                                    onImageChange={(fileOrUrl) => {
                                        // If it's a string, it's a URL; if it's an object, it's a File
                                        if (typeof fileOrUrl === 'string' || fileOrUrl === null) {
                                            setFormData({
                                                ...formData,
                                                imageUrl: fileOrUrl,
                                                file: undefined // Clear any previously selected file
                                            });
                                        } else {
                                            // It's a File object
                                            setFormData({
                                                ...formData,
                                                file: fileOrUrl,
                                                // Keep the existing imageUrl for now (it will be replaced after upload)
                                            });
                                        }
                                    }}
                                />

                                {/* Language tabs */}
                                <div className="language-tabs">
                                    {Object.entries(currentLanguages).map(([lang, enabled]) => {
                                        if (enabled) {
                                            const langName = languages.find(l => l.code === lang)?.name || lang;
                                            return (
                                                <button
                                                    key={lang}
                                                    type="button"
                                                    className={`language-tab ${activeLanguageTab === lang ? "active" : ""}`}
                                                    onClick={() => setActiveLanguageTab(lang)}
                                                >
                                                    {langName}
                                                </button>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>

                                {/* Collection specific fields */}
                                {activeTab === "collections" && (
                                    <div className="language-content">
                                        <div className="form-group">
                                            <label htmlFor="collectionName">
                                                Collection Name {activeLanguageTab !== 'en' ? `(${activeLanguageTab})` : ''}
                                            </label>
                                            <input
                                                type="text"
                                                id="collectionName"
                                                name="collectionName"
                                                placeholder={`Enter collection name in ${activeLanguageTab}`}
                                                value={getTranslation(formData.translations, activeLanguageTab, "collectionName")}
                                                onChange={handleInputChange}
                                                className="form-input"
                                                required={activeLanguageTab === "en"}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="description">
                                                Description {activeLanguageTab !== 'en' ? `(${activeLanguageTab})` : ''}
                                            </label>
                                            <textarea
                                                id="description"
                                                name="description"
                                                placeholder={`Enter description in ${activeLanguageTab}`}
                                                value={getTranslation(formData.translations, activeLanguageTab, "description")}
                                                onChange={handleInputChange}
                                                className="form-textarea"
                                                rows={3}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Brand specific fields */}
                                {activeTab === "brands" && (
                                    <div className="language-content">
                                        <div className="form-group">
                                            <label htmlFor="brandName">
                                                Brand Name {activeLanguageTab !== 'en' ? `(${activeLanguageTab})` : ''}
                                            </label>
                                            <input
                                                type="text"
                                                id="brandName"
                                                name="brandName"
                                                placeholder={`Enter brand name in ${activeLanguageTab}`}
                                                value={getTranslation(formData.translations, activeLanguageTab, "brandName")}
                                                onChange={handleInputChange}
                                                className="form-input"
                                                required={activeLanguageTab === "en"}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Occasion specific fields */}
                                {activeTab === "occasions" && (
                                    <div className="language-content">
                                        <div className="form-group">
                                            <label htmlFor="occasionName">
                                                Occasion Name {activeLanguageTab !== 'en' ? `(${activeLanguageTab})` : ''}
                                            </label>
                                            <input
                                                type="text"
                                                id="occasionName"
                                                name="occasionName"
                                                placeholder={`Enter occasion name in ${activeLanguageTab}`}
                                                value={getTranslation(formData.translations, activeLanguageTab, "occasionName")}
                                                onChange={handleInputChange}
                                                className="form-input"
                                                required={activeLanguageTab === "en"}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="dialog-footer">
                                <button type="button" className="cancel-button" onClick={() => setIsDialogOpen(false)}>
                                    Cancel
                                </button>
                                <button type="submit" className="submit-button">
                                    {isNewItem ? 'Create' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrandCollectionManager;