import React, { useEffect, useState } from 'react';
import { 
  Menu, 
  Home, 
  Package, 
  Users, 
  ShoppingCart, 
  Mail, 
  BarChart2, 
  Settings, 
  LogOut,
  ChevronLeft,
  ChevronRight,
  UserRoundCheck,
  BadgeDollarSign
} from 'lucide-react';
import './AdminLayout.css';
import Dashboard from './dashboard/DashBoard';
import ProductList from './products/ProductList';
import CustomerList from './CustomerList';
import MarketingAnalytics from './MarketingAnalytics';
import MenuManagement from './MenuManagement';
import OrderList from './orders/OrderList';
import VendorList from './VendorList';
import { useNavigate } from "react-router-dom";
import SubscriptionManager from './subscription/SubscriptionManager';
import BrandCollectionManager from './BrandCollectionManager';

const AdminLayout = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState('Dashboard');
  const [language, setLanguage] = useState('English');
  const [country, setCountry] = useState('USA');
  const[vendors,setVendor]=useState(localStorage.getItem("user"));
   const  navigate=useNavigate();
  
  useEffect(()=>{
    if(!vendors){
      navigate('login')
    }
  })
  const menuItems = [
    { title: 'Dashboard', icon: <Home size={20} />, component: <Dashboard /> },
    { title: 'Orders', icon: <ShoppingCart size={20} />, component: <OrderList /> },
    { title: 'Products', icon: <Package size={20} />, component: <ProductList /> },
    { title: 'Menu Management', icon: <Package size={20} />, component: <MenuManagement /> },
    { title: 'Essential Management', icon: <Package size={20} />, component: <BrandCollectionManager /> },
    { title: 'Customers', icon: <Users size={20} />, component: <CustomerList /> },
    { title: 'Vendors', icon: <UserRoundCheck size={20} />, component: <VendorList /> },
    { title: 'Subscription', icon: <BadgeDollarSign size={20} />, component: <SubscriptionManager /> },
    { title: 'Marketing', icon: <Mail size={20} />, component: <div>Marketing Component</div> },
    { title: 'Analytics', icon: <BarChart2 size={20} />, component: <MarketingAnalytics /> },
    { title: 'Settings', icon: <Settings size={20} />, component: <div>Settings Component</div> }
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const getCurrentComponent = () => {
    const menuItem = menuItems.find(item => item.title === currentPage);
    return menuItem ? menuItem.component : null;
  };

  const handleMenuClick = (title) => {
    setCurrentPage(title);
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };
  const handleLogout=()=>{
    localStorage.clear();
    navigate('login')

  }
  return (
    <div className="admin-container">
      <nav className="top-nav">
        <div className="nav-content">
          <div className="nav-left">
            <button className="mobile-menu-btn" onClick={toggleMobileMenu}>
              <Menu size={24} />
            </button>
            <div className="logo-space">
              <img className="logo-space" src="logo.jpg"/>
            </div>
            <span className="admin-title">MEME MALL MANAGER</span>
          </div>
          
          <div className="nav-right">
            <select 
              className="dropdown"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="Chinese">Chinese</option>
              <option value="Japanese">Japanese</option>
            </select>
            
            <select 
              className="dropdown"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="USA">USA</option>
              <option value="Spain">Spain</option>
              <option value="Taiwan">Taiwan</option>
              <option value="China">China</option>
              <option value="Japan">Japan</option>
              <option value="France">France</option>
            </select>
            
            <button className="logout-btn" onClick={()=>{handleLogout()}}>
              <LogOut size={20} />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </nav>

      <div className="main-container">
        <aside className={`sidebar ${isMobileMenuOpen ? 'mobile-open' : ''} ${isSidebarCollapsed ? 'collapsed' : ''}`}>
          <div className="sidebar-content">
            {menuItems.map((item) => (
              <button
                key={item.title}
                className={`menu-item ${currentPage === item.title ? 'active' : ''}`}
                onClick={() => handleMenuClick(item.title)}
              >
                {item.icon}
                <span>{item.title}</span>
              </button>
            ))}
          </div>
          <button 
            className="toggle-sidebar"
            onClick={toggleSidebar}
            title={isSidebarCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
          >
            {isSidebarCollapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
          </button>
        </aside>

        <main className={`main-content ${isSidebarCollapsed ? 'shifted' : ''}`}>
          <div className="page-header">
            <h1>{currentPage}</h1>
          </div>
          <div className="page-content">
            {getCurrentComponent()}
          </div>
        </main>
      </div>

      {isMobileMenuOpen && (
        <div className="mobile-overlay" onClick={toggleMobileMenu} />
      )}
    </div>
  );
};

export default AdminLayout;