import { useEffect, useState } from "react";
import { ChevronDown, ChevronRight, Plus, Trash2, Edit2 } from "lucide-react";
import "./MenuManagement.css";
import CategoryForm from "./category/CategoryForm";
import { API_ENDPOINTS_MENU } from '../util/apiConfig';
import axios from 'axios';


const MenuManagement = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updateId, setUpdateId] = useState(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS_MENU.MENU);
        setCategories(response.data.categories); // Ensure response contains expected category structure
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenu();
  }, []);
  const handleDelete=async (id)=>{
     try {
      await axios.delete(`${API_ENDPOINTS_MENU.ADD_MENU}/${id}`);
      setCategories((categories) => categories.filter(category => category.id !== id));
      setCategories((categories) => categories.filter(category => category.filter(cat=>cat.id!==id)));

    } catch (error) {
      console.error("Error deleting category:", error);
    }
  }
  const toggleCategory = (categoryId) => {
    setCategories((prev) =>
      prev.map((cat) => (cat.id === categoryId ? { ...cat, isOpen: !cat.isOpen } : cat))
    );
  };

  const handleEdit = (id) => {
    setIsEditModalOpen(true);
    setUpdateId(id)
  };

  return (
    <div className="menu-container">
      <h2 className="menu-title"></h2>

      {isEditModalOpen ? (
        <CategoryForm updateId={updateId} categories={categories}/>
      ) : (
        <>
     

          <div className="menu-list">
            {categories.map((category) => (
              <div key={category.id} className="menu-card">
                <div className="menu-header" onClick={() => toggleCategory(category.id)}>
                  {category.isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  <span>{category.name}</span>
                  <div className="menu-actions">
                    <button className="edit-btn" onClick={() => handleEdit(category.id,categories)}><Edit2 size={16} /></button>
                    <button className="delete-btn"><Trash2 size={16} onClick={() => handleDelete(category.id)} /></button>
                  </div>
                </div>
                {category.isOpen && (
                  <div className="submenu">
                    {category.children.map((sub) => (
                      <div key={sub.id} className="submenu-item">
                        {sub.name}
                        <div className="menu-actions">
                          <button className="edit-btn"><Edit2 size={16} onClick={() => handleEdit(sub.id,categories)}/></button>
                          <button className="delete-btn"><Trash2 size={16} onClick={() => handleDelete(sub.id)}/></button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <button className="add-menu-btn" onClick={() => handleEdit()}>
            <Plus size={18} /> Add Menu Item
          </button>
        </>
      )}
    </div>
  );
};

export default MenuManagement;
