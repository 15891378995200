import { useState, useEffect } from 'react';
import { X, Save, AlertCircle, Plus, Trash2, Image } from 'lucide-react';
import './EditProductForm.css'; // Reuse the same CSS
import { API_ENDPOINTS } from '../../util/apiConfig';
import axios from 'axios';
import ProductGallery from './ProductGallery';
import MultiSelectDropdown from './MultiSelectDropdown';

const ProductAddForm = ({ onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [activeTab, setActiveTab] = useState('en');
  const [showGalleryTab, setShowGalleryTab] = useState(false);
  const[vendors,setVendor]=useState(localStorage.getItem("user"));

  // Options for dropdowns (will be fetched from API)
  const [collectionsOptions, setCollectionsOptions] = useState([]);
  const [occasionsOptions, setOccasionsOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  
  // Loading states for dropdowns
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [loadingOccasions, setLoadingOccasions] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(false);

  const languages = [
    { code: 'en', name: 'English', isDefault: true },
    { code: 'cn', name: 'Chinese' },
    { code: 'jp', name: 'Japanese' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'tw', name: 'Taiwan' }
  ];

  const [selectedLangs, setSelectedLangs] = useState(['en']);
  const [formData, setFormData] = useState({});
  const [languageSpecifications, setLanguageSpecifications] = useState({});

  // Initialize empty form data on component mount
  useEffect(() => {
    initializeEmptyFormData();
    fetchDropdownOptions();
  }, []);

  // Fetch dropdown options from APIs
  const fetchDropdownOptions = async () => {
    try {
      // Fetch collections
      setLoadingCollections(true);
      const collectionsResponse = await axios.get(API_ENDPOINTS.COLLECTION+'/en');
      setCollectionsOptions(collectionsResponse.data.map(collection => ({
        value: collection.collectionName,
        label: collection.collectionName
      })));
      setLoadingCollections(false);

      // Fetch occasions
      setLoadingOccasions(true);
      const occasionsResponse = await axios.get(API_ENDPOINTS.OCCATIONS+'/en');
      setOccasionsOptions(occasionsResponse.data.map(occasion => ({
        value: occasion.name,
        label: occasion.name
      })));
      setLoadingOccasions(false);

      // Fetch brands
      setLoadingBrands(true);
      const brandsResponse = await axios.get(API_ENDPOINTS.BRANDS+'/en');
      setBrandsOptions(brandsResponse.data.map(brand => ({
        value: brand.name,
        label: brand.name
      })));
      setLoadingBrands(false);
    } catch (err) {
      console.error("Error fetching dropdown options:", err);
      setLoadingCollections(false);
      setLoadingOccasions(false);
      setLoadingBrands(false);
    }
  };

  // Initialize form with empty data
  const initializeEmptyFormData = () => {
    // Create initial form structure
    const initialData = {};
    
    // Initialize with empty data for all supported languages
    languages.forEach(lang => {
      initialData[lang.code] = {
        name: '',
        description: '',
        slug: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        brand: '',
        brands: [], // New field for dropdown selected brands
        collectionName: '',
        collections: [], // New field for dropdown selected collections
        tags: [],
        occasions: [] // This will now store dropdown selected occasions
      };
    });

    // Initialize specifications by language
    const specs = {};
    languages.forEach(lang => {
      specs[lang.code] = [];
    });
    
    setLanguageSpecifications(specs);
    
    // Add common product data structure
    const commonData = {
      images: [],
      originalPrice: 0,
      sellingPrice: 0,
      discountPercentage: 0,
      validFrom: null,
      validUntil: null,
      variants: [],
      stock: 0,
      isActive: true
    };
    
    setFormData({ ...initialData, common: commonData });
  };

  // Handle language selection changes
  const handleLangChange = (langCode) => {
    setSelectedLangs(prev => {
      const updated = prev.includes(langCode) 
        ? prev.filter(code => code !== langCode)
        : [...prev, langCode];
      
      // Validate form after language selection change
      validateForm(updated);
      return updated;
    });
  };

  // Handle form input changes
  const handleInputChange = (lang, field, value) => {
    setFormData(prev => {
      const updated = {
        ...prev,
        [lang]: { ...prev[lang], [field]: value }
      };
      
      // Validate if the current field is required
      validateField(lang, field, value);
      return updated;
    });
  };

  // For chip input fields
  const handleChipInputKeyDown = (e, lang, field) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      e.preventDefault();
      const newValue = e.target.value.trim();
      
      setFormData(prev => {
        const currentValues = [...(prev[lang][field] || [])];
        if (!currentValues.includes(newValue)) {
          const updated = {
            ...prev,
            [lang]: { ...prev[lang], [field]: [...currentValues, newValue] }
          };
          return updated;
        }
        return prev;
      });
      
      e.target.value = '';
    }
  };

  // Remove a chip
  const removeChip = (lang, field, index) => {
    setFormData(prev => {
      const currentValues = [...(prev[lang][field] || [])];
      currentValues.splice(index, 1);
      
      return {
        ...prev,
        [lang]: { ...prev[lang], [field]: currentValues }
      };
    });
  };

  // Handle dropdown selection change
  const handleDropdownChange = (lang, field, values) => {
    setFormData(prev => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: values }
    }));

    // For brand field, also update the single brand field for backward compatibility
    if (field === 'brands' && values.length > 0) {
      setFormData(prev => ({
        ...prev,
        [lang]: { ...prev[lang], brand: values[0] }
      }));
    }

    // For collections field, also update the single collectionName field
    if (field === 'collections' && values.length > 0) {
      setFormData(prev => ({
        ...prev,
        [lang]: { ...prev[lang], collectionName: values[0] }
      }));
    }
  };

  // Validate a single field
  const validateField = (lang, field, value) => {
    setValidationErrors(prev => {
      const errors = { ...prev };
      const fieldKey = `${lang}.${field}`;
      
      // Required fields validation for default language
      if (lang === 'en' && ['name', 'description', 'slug'].includes(field)) {
        if (!value || value.trim() === '') {
          errors[fieldKey] = `${field} is required for ${languages.find(l => l.code === lang).name}`;
        } else {
          delete errors[fieldKey];
        }
      }
      
      // For non-default languages with some data entered, validate completeness
      if (lang !== 'en' && selectedLangs.includes(lang)) {
        const langForm = formData[lang] || {};
        const hasAnyData = Object.values(langForm).some(val => val && val.trim !== '');
        
        if (hasAnyData && ['name', 'description'].includes(field) && (!value || value.trim() === '')) {
          errors[fieldKey] = `${field} is required when providing translations`;
        } else if (!hasAnyData || (value && value.trim !== '')) {
          delete errors[fieldKey];
        }
      }
      
      return errors;
    });
  };

  // Validate the entire form
  const validateForm = (langs = selectedLangs) => {
    const errors = {};
    
    // Validate required fields for default language
    const requiredFields = ['name', 'description', 'slug'];
    requiredFields.forEach(field => {
      if (!formData.en || !formData.en[field] || formData.en[field].trim() === '') {
        errors[`en.${field}`] = `${field} is required for English`;
      }
    });
    
    // For each selected non-default language, validate consistency
    langs.forEach(lang => {
      if (lang !== 'en') {
        const langForm = formData[lang] || {};
        const hasAnyData = Object.values(langForm).some(val => val && val.trim !== '');
        
        if (hasAnyData) {
          // If any data is provided for this language, ensure required fields are filled
          ['name', 'description'].forEach(field => {
            if (!langForm[field] || langForm[field].trim() === '') {
              errors[`${lang}.${field}`] = `${field} is required when providing ${languages.find(l => l.code === lang).name} translations`;
            }
          });
        }
      }
    });
    
    setValidationErrors(errors);
    setFormValid(Object.keys(errors).length === 0);
    return Object.keys(errors).length === 0;
  };

  // Add a new specification for the active language
  const addSpecification = () => {
    setLanguageSpecifications(prev => {
      const currentSpecs = [...(prev[activeTab] || [])];
      currentSpecs.push({ name: '', value: '', additionalInfo: '' });
      
      return {
        ...prev,
        [activeTab]: currentSpecs
      };
    });
  };

  // Update a specification for the active language
  const updateSpecification = (index, field, value) => {
    setLanguageSpecifications(prev => {
      const currentSpecs = [...(prev[activeTab] || [])];
      currentSpecs[index] = {
        ...currentSpecs[index],
        [field]: value
      };
      
      return {
        ...prev,
        [activeTab]: currentSpecs
      };
    });
  };

  // Remove a specification from the active language
  const removeSpecification = (index) => {
    setLanguageSpecifications(prev => {
      const currentSpecs = [...(prev[activeTab] || [])];
      currentSpecs.splice(index, 1);
      
      return {
        ...prev,
        [activeTab]: currentSpecs
      };
    });
  };

  // Add a new variant
  const addVariant = () => {
    setFormData(prev => {
      const variants = [...(prev.common.variants || [])];
      variants.push({ 
        name: '', 
        sku: '',
        price: 0,
        stock: 0,
        color: '',
        size: '',
        attributes: [] 
      });
      
      return {
        ...prev,
        common: {
          ...prev.common,
          variants
        }
      };
    });
  };

  // Update a variant
  const updateVariant = (index, field, value) => {
    setFormData(prev => {
      const variants = [...(prev.common.variants || [])];
      variants[index] = {
        ...variants[index],
        [field]: value
      };
      
      // If updating color or size, update the name to reflect these values
      if (field === 'color' || field === 'size') {
        const color = field === 'color' ? value : variants[index].color || '';
        const size = field === 'size' ? value : variants[index].size || '';
        
        if (color && size) {
          variants[index].name = `${color} - ${size}`;
        } else if (color) {
          variants[index].name = color;
        } else if (size) {
          variants[index].name = size;
        }
      }
      
      return {
        ...prev,
        common: {
          ...prev.common,
          variants
        }
      };
    });
  };

  // Remove a variant
  const removeVariant = (index) => {
    setFormData(prev => {
      const variants = [...(prev.common.variants || [])];
      variants.splice(index, 1);
      
      return {
        ...prev,
        common: {
          ...prev.common,
          variants
        }
      };
    });
  };

  // Handle tab change
  const handleTabChange = (tabId) => {
    if (tabId === 'gallery') {
      setShowGalleryTab(true);
    } else {
      setShowGalleryTab(false);
      setActiveTab(tabId);
    }
  };

  // Handle image updates from ProductGallery component
  const handleImagesChange = (images) => {
    setFormData(prev => ({
      ...prev,
      common: {
        ...prev.common,
        images
      }
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Final validation before submission
    if (!validateForm()) {
      return;
    }
    
    // Prepare translations data for API
    const translations = selectedLangs.map(lang => {
      if (!formData[lang]) return null;
      
      return {
        languagecode: lang,
        name: formData[lang].name,
        description: formData[lang].description,
        slug: formData[lang].slug || (lang === 'en' ? formData.en.slug : ''),
        metaTitle: formData[lang].metaTitle || '',
        metaDescription: formData[lang].metaDescription || '',
        metaKeywords: formData[lang].metaKeywords || '',
        brand: formData[lang].brands.length > 0 ? formData[lang].brands[0] : (lang === 'en' ? formData.en.brand : ''),
        collectionName: formData[lang].collections.length > 0 ? formData[lang].collections[0] : (lang === 'en' ? formData.en.collectionName : ''),
        tags: formData[lang].tags.join(','),
        occasions: formData[lang].occasions.join(',')
      };
    }).filter(Boolean);
    
    // Prepare specifications data for API
    const specifications = [];
    Object.entries(languageSpecifications).forEach(([langCode, specs]) => {
      specs.forEach(spec => {
        if (spec.name && spec.value) {
          specifications.push({
            specType: spec.name,
            specValue: spec.value,
            additionalInfo: spec.additionalInfo || '',
            languageCode: langCode
          });
        }
      });
    });
    
    // Prepare variants data for API
    const variants = formData.common.variants.map(variant => {
      return {
        color: variant.color || '',
        size: variant.size || '',
        stock: variant.stock || 0,
        sellinglPrice: variant.sellingPrice || 0,
        actualPrice: variant.actualPrice || 0,
        sku: variant.sku || '',
        material: null,
        dimensions: null,
        weight: null
      };
    });
    const formatDateTime = (date) => {
        return date ? new Date(date).toISOString().slice(0, 19) : null;
      };
    const productData = {
      vendorId:vendors.id,
      images:[],
      discountPercentage: formData.common.discountPercentage,
      validFrom: formatDateTime(formData.common.validFrom),
      validUntil: formatDateTime(formData.common.validUntil),
      variants: variants,
      specifications: specifications,
      stock: formData.common.stock,
      isActive: formData.common.isActive,
      translations: translations
    };
    const formPayload = new FormData();
    formPayload.append('createDTO', new Blob([JSON.stringify(productData)], {
        type: 'application/json'
      }));
      formData.common.images.forEach((img) => {        
        formPayload.append('image', img.file);
      });
    try {
      setLoading(true);
      const response = await axios.post(API_ENDPOINTS.PRODUCT_UPDATE, formPayload, {
        headers: {
          "Content-Type": "multipart/form-data", 
        },
      });
      if (response.data) {
        if (onSuccess) {
          onSuccess(response.data);
        }
        onClose();
      }
    } catch (err) {
      setError(err.message || 'Error creating product');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Add New Product</h2>
          <button onClick={onClose} className="close-button">
            <X className="icon" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="edit-form">
          <div className="language-section">
            <label className="section-label">Languages</label>
            <div className="language-checkboxes">
              {languages.map(lang => (
                <label key={lang.code} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedLangs.includes(lang.code)}
                    onChange={() => !lang.isDefault && handleLangChange(lang.code)}
                    disabled={lang.isDefault}
                  />
                  <span>{lang.name}</span>
                </label>
              ))}
            </div>
          </div>

          {!formValid && (
            <div className="validation-error-summary">
              <AlertCircle className="icon" />
              <p>Please fix the validation errors before saving</p>
            </div>
          )}

          <div className="form-tabs">
            <div className="tabs-header">
              <button 
                type="button"
                className={`tab-button ${showGalleryTab ? 'active' : ''}`}
                onClick={() => handleTabChange('gallery')}
              >
                <Image size={16} />
                Gallery
              </button>
              {selectedLangs.map(lang => (
                <button 
                  key={lang} 
                  type="button"
                  className={`tab-button ${!showGalleryTab && activeTab === lang ? 'active' : ''} ${Object.keys(validationErrors).some(key => key.startsWith(lang)) ? 'has-errors' : ''}`}
                  onClick={() => handleTabChange(lang)}
                >
                  {languages.find(l => l.code === lang).name}
                  {lang === 'en' && <span className="default-label">(Default)</span>}
                  {Object.keys(validationErrors).some(key => key.startsWith(lang)) && (
                    <AlertCircle className="error-icon" />
                  )}
                </button>
              ))}
            </div>
          </div>

          {showGalleryTab && (
            <ProductGallery 
              images={formData.common?.images || []} 
              onChange={handleImagesChange} 
            />
          )}

          {!showGalleryTab && selectedLangs.map(lang => (
            <div 
              key={lang} 
              className="language-form" 
              style={{ display: activeTab === lang ? 'block' : 'none' }}
            >
              <h3 className="language-title">
                {languages.find(l => l.code === lang).name}
                {lang === 'en' && <span className="default-label">(Default)</span>}
              </h3>
              
              <div className="form-fields">
                <div className="form-row">
                  <div className="form-group">
                    <label>Name*</label>
                    <input
                      type="text"
                      value={formData[lang]?.name || ''}
                      onChange={(e) => handleInputChange(lang, 'name', e.target.value)}
                      required={lang === 'en'}
                      placeholder={`Product name in ${languages.find(l => l.code === lang).name}`}
                      className={validationErrors[`${lang}.name`] ? 'error' : ''}
                    />
                    {validationErrors[`${lang}.name`] && (
                      <div className="error-message">{validationErrors[`${lang}.name`]}</div>
                    )}
                  </div>

                  {lang === 'en' && (
                    <div className="form-group">
                      <label>Slug*</label>
                      <input
                        type="text"
                        value={formData[lang]?.slug || ''}
                        onChange={(e) => handleInputChange(lang, 'slug', e.target.value)}
                        required={lang === 'en'}
                        placeholder="Product URL slug"
                        className={validationErrors[`${lang}.slug`] ? 'error' : ''}
                      />
                      {validationErrors[`${lang}.slug`] && (
                        <div className="error-message">{validationErrors[`${lang}.slug`]}</div>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label>Description*</label>
                  <textarea
                    value={formData[lang]?.description || ''}
                    onChange={(e) => handleInputChange(lang, 'description', e.target.value)}
                    required={lang === 'en'}
                    placeholder={`Product description in ${languages.find(l => l.code === lang).name}`}
                    className={validationErrors[`${lang}.description`] ? 'error' : ''}
                  />
                  {validationErrors[`${lang}.description`] && (
                    <div className="error-message">{validationErrors[`${lang}.description`]}</div>
                  )}
                </div>

                <div className="form-row">
                  {/* Replace brand input with MultiSelectDropdown */}
                  <MultiSelectDropdown
                    label="Brand"
                    placeholder="Select brand"
                    options={brandsOptions}
                    selectedValues={formData[lang]?.brands || []}
                    onChange={(values) => handleDropdownChange(lang, 'brands', values)}
                    isLoading={loadingBrands}
                  />

                  {/* Replace collection input with MultiSelectDropdown */}
                  <MultiSelectDropdown
                    label="Collection"
                    placeholder="Select collection"
                    options={collectionsOptions}
                    selectedValues={formData[lang]?.collections || []}
                    onChange={(values) => handleDropdownChange(lang, 'collections', values)}
                    isLoading={loadingCollections}
                  />
                </div>

                <div className="form-row">
                  <div className="form-group chip-input-container">
                    <label>Tags</label>
                    <div className="chip-input-wrapper">
                      <div className="chips-container">
                        {formData[lang]?.tags.map((tag, index) => (
                          <div key={index} className="chip">
                            <span>{tag}</span>
                            <button
                              type="button"
                              className="chip-remove"
                              onClick={() => removeChip(lang, 'tags', index)}
                            >
                              <X size={12} />
                            </button>
                          </div>
                        ))}
                      </div>
                      <input
                        type="text"
                        placeholder="Type and press Enter to add"
                        onKeyDown={(e) => handleChipInputKeyDown(e, lang, 'tags')}
                        className="chip-text-input"
                      />
                    </div>
                  </div>

                  {/* Replace occasions chip input with MultiSelectDropdown */}
                  <MultiSelectDropdown
                    label="Occasions"
                    placeholder="Select occasions"
                    options={occasionsOptions}
                    selectedValues={formData[lang]?.occasions || []}
                    onChange={(values) => handleDropdownChange(lang, 'occasions', values)}
                    isLoading={loadingOccasions}
                  />
                </div>

                <div className="meta-section">
                  <h4>SEO Metadata</h4>
                  <div className="form-group">
                    <label>Meta Title</label>
                    <input
                      type="text"
                      value={formData[lang]?.metaTitle || ''}
                      onChange={(e) => handleInputChange(lang, 'metaTitle', e.target.value)}
                      placeholder="SEO meta title"
                    />
                  </div>

                  <div className="form-group">
                    <label>Meta Description</label>
                    <textarea
                      value={formData[lang]?.metaDescription || ''}
                      onChange={(e) => handleInputChange(lang, 'metaDescription', e.target.value)}
                      placeholder="SEO meta description"
                      className="meta-textarea"
                    />
                  </div>

                  <div className="form-group">
                    <label>Meta Keywords</label>
                    <input
                      type="text"
                      value={formData[lang]?.metaKeywords || ''}
                      onChange={(e) => handleInputChange(lang, 'metaKeywords', e.target.value)}
                      placeholder="SEO keywords (comma separated)"
                    />
                  </div>
                </div>
              </div>
              
              {/* Specifications Section - Now language-specific */}
              <div className="product-section">
                <h3 className="section-title">Specifications ({languages.find(l => l.code === lang).name})</h3>
                <div className="specifications-container">
                  {languageSpecifications[lang]?.map((spec, index) => (
                    <div key={index} className="specification-item">
                      <div className="spec-inputs">
                        <input
                          type="text"
                          value={spec.name || ''}
                          onChange={(e) => updateSpecification(index, 'name', e.target.value)}
                          placeholder="Specification name"
                          className="spec-name"
                        />
                        <input
                          type="text"
                          value={spec.value || ''}
                          onChange={(e) => updateSpecification(index, 'value', e.target.value)}
                          placeholder="Specification value"
                          className="spec-value"
                        />
                        <input
                          type="text"
                          value={spec.additionalInfo || ''}
                          onChange={(e) => updateSpecification(index, 'additionalInfo', e.target.value)}
                          placeholder="Additional info"
                          className="spec-info"
                        />
                      </div>
                      <button 
                        type="button" 
                        className="remove-spec"
                        onClick={() => removeSpecification(index)}
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  ))}
                  <button 
                    type="button" 
                    className="add-spec-button"
                    onClick={addSpecification}
                  >
                    <Plus size={16} />
                    Add Specification
                  </button>
                </div>
              </div>
            </div>
          ))}

          {/* Pricing Section */}
          <div className="product-section">
            <h3 className="section-title">Pricing</h3>
            <div className="pricing-container">
              <div className="form-row">
                <div className="form-group">
                  <label>Discount Percentage</label>
                  <input
                    type="number"
                    value={formData.common?.discountPercentage || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      common: { ...prev.common, discountPercentage: parseFloat(e.target.value) }
                    }))}
                    placeholder="Discount %"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Valid From</label>
                  <input
                    type="date"
                    value={formData.common?.validFrom || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      common: { ...prev.common, validFrom: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>Valid Until</label>
                  <input
                    type="date"
                    value={formData.common?.validUntil || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      common: { ...prev.common, validUntil: e.target.value }
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Variants Section - Common across languages */}
          <div className="product-section">
            <h3 className="section-title">Variants</h3>
            <div className="variants-container">
              {formData.common?.variants?.map((variant, index) => (
                <div key={index} className="variant-item">
                  <div className="variant-header">
                    <h4>Variant #{index + 1}</h4>
                    <button 
                      type="button" 
                      className="remove-variant"
                      onClick={() => removeVariant(index)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                  <div className="variant-form">
                    <div className="form-row">
                      <div className="form-group">
                        <label>Color</label>
                        <input
                          type="text"
                          value={variant.color || ''}
                          onChange={(e) => updateVariant(index, 'color', e.target.value)}
                          placeholder="Variant color"
                        />
                      </div>
                      <div className="form-group">
                        <label>Size</label>
                        <input
                          type="text"
                          value={variant.size || ''}
                          onChange={(e) => updateVariant(index, 'size', e.target.value)}
                          placeholder="Variant size"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group">
                        <label>Actual Price</label>
                        <input
                          type="number"
                          value={variant.actualprice || ''}
                          onChange={(e) => updateVariant(index, 'actualPrice', parseFloat(e.target.value))}
                          placeholder="Actual price"
                        />
                      </div>
                      <div className="form-group">
                        <label>Selling Price</label>
                        <input
                          type="number"
                          value={variant.sellingPrice || ''}
                          onChange={(e) => updateVariant(index, 'sellingPrice', parseFloat(e.target.value))}
                          placeholder="Selling price"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                     
                      <div className="form-group">
                        <label>SKU</label>
                        <input
                          type="text"
                          value={variant.sku || ''}
                          onChange={(e) => updateVariant(index, 'sku', e.target.value)}
                          placeholder="Stock keeping unit"
                        />
                      </div>
                      <div className="form-group">
                        <label>Stock</label>
                        <input
                          type="number"
                          value={variant.stock || ''}
                          onChange={(e) => updateVariant(index, 'stock', parseInt(e.target.value))}
                          placeholder="Available stock"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <button 
                type="button" 
                className="add-variant-button"
                onClick={addVariant}
                >
                  <Plus size={16} />
                  Add Variant
                </button>
              </div>
            </div>
  
            <div className="form-actions">
              <button type="button" onClick={onClose} className="cancel-button">
                Cancel
              </button>
              <button 
                type="submit" 
                className="save-button" 
                disabled={!formValid}
              >
                <Save className="icon" />
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  export default ProductAddForm;