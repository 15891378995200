import React, { useRef, useState } from 'react';

const ImageUploadSection = ({ currentImage, onImageChange }) => {
    const fileInputRef = useRef(null);
    const [previewUrl, setPreviewUrl] = useState(currentImage || '');
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a preview URL for the UI
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            
            // Pass the file object to parent component
            onImageChange(file);
        }
    };
    
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };
    
    return (
        <div className="image-upload-section">
            <div className="preview-container">
                {previewUrl ? (
                    <img 
                        src={previewUrl} 
                        alt="Preview" 
                        className="image-preview" 
                    />
                ) : (
                    <div className="no-image-preview">No image selected</div>
                )}
            </div>
            
            <div className="upload-controls">
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                />
                <button 
                    type="button"
                    className="upload-button"
                    onClick={triggerFileInput}
                >
                    {currentImage ? 'Change Image' : 'Upload Image'}
                </button>
                {previewUrl && (
                    <button
                        type="button"
                        className="remove-button"
                        onClick={() => {
                            setPreviewUrl('');
                            onImageChange(null);
                        }}
                    >
                        Remove Image
                    </button>
                )}
            </div>
        </div>
    );
};

export default ImageUploadSection;