import React from 'react';
import { BarChart, Bar, LineChart, Line, AreaChart, Area, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { ArrowUpRight, ArrowDownRight, Users, Target, Share2, DollarSign } from 'lucide-react';
import './MarketingAnalytics.css';

const MarketingAnalytics = () => {
  // Sample data for different metrics
  const campaignData = [
    { name: 'Email', visitors: 4000, conversions: 2400, revenue: 5600 },
    { name: 'Social', visitors: 3000, conversions: 1398, revenue: 4200 },
    { name: 'Search', visitors: 2000, conversions: 9800, revenue: 7800 },
    { name: 'Direct', visitors: 2780, conversions: 3908, revenue: 3200 },
    { name: 'Referral', visitors: 1890, conversions: 4800, revenue: 2500 },
  ];

  const socialMediaData = [
    { name: 'Facebook', value: 4000 },
    { name: 'Instagram', value: 3000 },
    { name: 'Twitter', value: 2000 },
    { name: 'LinkedIn', value: 1500 },
  ];

  const conversionData = [
    { name: 'Jan', rate: 2.8 },
    { name: 'Feb', rate: 3.2 },
    { name: 'Mar', rate: 3.8 },
    { name: 'Apr', rate: 3.5 },
    { name: 'May', rate: 4.2 },
    { name: 'Jun', rate: 4.0 },
  ];

  const funnelData = [
    { stage: 'Visits', count: 10000 },
    { stage: 'Sign-ups', count: 5000 },
    { stage: 'Active Users', count: 2500 },
    { stage: 'Subscriptions', count: 1000 },
  ];

  return (
    <div className="marketing-analytics">
      {/* Stats Overview */}
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#e0f2fe' }}>
            <Users size={24} color="#0284c7" />
          </div>
          <div className="stat-info">
            <h3>Total Visitors</h3>
            <div className="stat-value">128.5K</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>12.5% vs last month</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#dcfce7' }}>
            <Target size={24} color="#16a34a" />
          </div>
          <div className="stat-info">
            <h3>Conversion Rate</h3>
            <div className="stat-value">3.8%</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>0.5% vs last month</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#fef3c7' }}>
            <Share2 size={24} color="#d97706" />
          </div>
          <div className="stat-info">
            <h3>Social Engagement</h3>
            <div className="stat-value">24.2K</div>
            <div className="stat-change negative">
              <ArrowDownRight size={16} />
              <span>2.1% vs last month</span>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon" style={{ backgroundColor: '#ffe4e6' }}>
            <DollarSign size={24} color="#e11d48" />
          </div>
          <div className="stat-info">
            <h3>Campaign ROI</h3>
            <div className="stat-value">285%</div>
            <div className="stat-change positive">
              <ArrowUpRight size={16} />
              <span>15.2% vs last month</span>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="charts-grid">
        {/* Campaign Performance */}
        <div className="chart-card full-width">
          <h3>Campaign Performance</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={campaignData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="visitors" fill="#3b82f6" name="Visitors" />
                <Bar dataKey="conversions" fill="#16a34a" name="Conversions" />
                <Bar dataKey="revenue" fill="#d97706" name="Revenue" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Conversion Rate Trend */}
        <div className="chart-card">
          <h3>Conversion Rate Trend</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={conversionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="rate" stroke="#3b82f6" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Social Media Distribution */}
        <div className="chart-card">
          <h3>Social Media Distribution</h3>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={socialMediaData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#3b82f6"
                  label
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Conversion Funnel */}
        <div className="chart-card">
          <h3>Conversion Funnel</h3>
          <div className="funnel-container">
            {funnelData.map((stage, index) => (
              <div key={stage.stage} className="funnel-stage">
                <div className="funnel-label">
                  <span>{stage.stage}</span>
                  <span>{stage.count.toLocaleString()}</span>
                </div>
                <div 
                  className="funnel-bar"
                  style={{ 
                    width: `${(stage.count / funnelData[0].count) * 100}%`,
                    backgroundColor: `hsl(${220 - index * 30}, 70%, 50%)`
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingAnalytics;