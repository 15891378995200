import React, { useEffect, useState } from 'react';
import { Search, Plus, Edit2, Trash2, Filter, ArrowUpDown, X, Check, ChevronLeft, ChevronRight } from 'lucide-react';
import './ProductList.css';
import ProductEditForm from './EditProductForm';
import { API_ENDPOINTS } from '../../util/apiConfig';
import axios from 'axios';
import ProductAddForm from './ProductAddForm';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditModalOpenNew, setIsEditModalOpenNew] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeOptions] = useState([5, 10, 20, 50]);

  // Filter products based on search term and category
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = filterCategory === 'All' || product.category === filterCategory;
    return matchesSearch && matchesCategory;
  });
  
  // Calculate total pages
  const totalPages = Math.ceil(totalProducts / pageSize);
  
  // Calculate current offset for API request
  const currentOffset = (currentPage - 1) * pageSize;
  
  useEffect(() => {
    fetchProducts();
  }, [currentPage, pageSize]);
  
  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      let params = {
        "offset": currentOffset,
        "pagesize": pageSize,
        "languageCode": "en"
      };
      const response = await axios.post(API_ENDPOINTS.PRODUCTS, params);
      setProducts(response.data.content);
      setTotalProducts(response.data.total || response.data.content.length);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Reset to first page when search or filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filterCategory]);

  // Sort products
  const sortProducts = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedProducts = () => {
    if (!sortConfig.key) return filteredProducts;

    return [...filteredProducts].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  // Open delete confirmation modal
  const openDeleteConfirmation = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!productToDelete) return;
    
    setIsLoading(true);
    try {
      // Call delete API
      await axios.delete(`${API_ENDPOINTS.PRODUCT_UPDATE}/${productToDelete.id}`);
      
      // Update local state to remove product
      setProducts(products.filter(product => product.id !== productToDelete.id));
      setTotalProducts(prevTotal => prevTotal - 1);
      
      // Show success message
      setSuccessMessage('Product deleted successfully');
      setTimeout(() => setSuccessMessage(''), 3000); // Hide message after 3 seconds
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false); // Close modal
      setProductToDelete(null);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setIsEditModalOpen(true);
  };
  
  const handleNewProduct = () => {
    setIsEditModalOpenNew(true);
  };
  
  const getStatusClass = (status) => {
    if (!status) return '';
    
    switch (status.toLowerCase()) {
      case 'in stock':
        return 'status-in-stock';
      case 'out of stock':
        return 'status-out-of-stock';
      case 'low stock':
        return 'status-low-stock';
      default:
        return '';
    }
  };
  
  // Pagination handlers
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };
  
  // Generate page buttons
  const generatePageButtons = () => {
    const buttons = [];
    const maxVisiblePages = 5; // Maximum number of page buttons to show
    
    // Calculate range of visible page buttons
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = startPage + maxVisiblePages - 1;
    
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
    
    // Add page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => goToPage(i)}
        >
          {i}
        </button>
      );
    }
    
    return buttons;
  };
  
  // Calculate display range for pagination info
  const startItem = totalProducts === 0 ? 0 : currentOffset + 1;
  const endItem = Math.min(currentOffset + pageSize, totalProducts);

  return (
    <div className="product-list">
      {/* Header Actions */}
      <div className="list-header">
        <h2>Products</h2>
        <button className="btn-primary" onClick={handleNewProduct}>
          <Plus size={16} />
          Add Product
        </button>
      </div>

      {/* Success Message */}
      {successMessage && (
        <div className="success-message">
          <Check size={16} />
          {successMessage}
        </div>
      )}

      {/* Filters */}
      <div className="filters">
        <div className="search-box">
          <Search size={20} />
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-box">
          <Filter size={20} />
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="All">All Categories</option>
            <option value="Electronics">Electronics</option>
            <option value="Home">Home</option>
            <option value="Fashion">Fashion</option>
          </select>
        </div>
      </div>

      {/* Products Table */}
      <div className="table-container">
        {isLoading && <div className="loading-overlay">Loading...</div>}
        <table>
          <thead>
            <tr>
              <th onClick={() => sortProducts('name')}>
                Product Name
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortProducts('category')}>
                Brand
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortProducts('price')}>
                Price
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortProducts('stock')}>
                Stock
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortProducts('status')}>
                Status
                <ArrowUpDown size={16} />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {getSortedProducts().length > 0 ? (
              getSortedProducts().map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.brand}</td>
                  <td>${product.sellingPrice ? product.sellingPrice.toFixed(2) : 0}</td>
                  <td>{product.stocks}</td>
                  <td>
                    <span className={`status-badge ${getStatusClass(product.status)}`}>
                      {product.status}
                    </span>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button className="btn-icon" onClick={() => handleEdit(product)}>
                        <Edit2 size={16} />
                      </button>
                      <button className="btn-icon delete" onClick={() => openDeleteConfirmation(product)}>
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center', padding: '24px' }}>
                  No products found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* Pagination */}
      <div className="pagination">
        <div className="pagination-info">
          Showing {startItem} to {endItem} of {totalProducts} products
        </div>
        
        <div className="pagination-controls">
          <button 
            className="page-button nav" 
            disabled={currentPage === 1}
            onClick={() => goToPage(currentPage - 1)}
          >
            <ChevronLeft size={16} />
            Prev
          </button>
          
          {generatePageButtons()}
          
          <button 
            className="page-button nav" 
            disabled={currentPage === totalPages}
            onClick={() => goToPage(currentPage + 1)}
          >
            Next
            <ChevronRight size={16} />
          </button>
          
          <div className="page-select">
            <span>Show:</span>
            <select value={pageSize} onChange={handlePageSizeChange}>
              {pageSizeOptions.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content delete-confirmation">
            <h3>Delete Product</h3>
            <p>Are you sure you want to delete this product?</p>
            <div className="modal-actions">
              <button className="btn-secondary" onClick={() => setShowDeleteModal(false)}>
                No
              </button>
              <button className="btn-danger" onClick={handleDelete}>
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Product Modal */}
      {isEditModalOpen && (
        <ProductEditForm
          productId={selectedProduct.id}
          onClose={() => {
            setIsEditModalOpen(false);
            fetchProducts(); // Refresh products after edit
          }}
        />
      )}
      
      {isEditModalOpenNew && (
        <ProductAddForm
          onClose={() => {
            setIsEditModalOpenNew(false);
            fetchProducts(); // Refresh products after add
          }}
        />
      )}
    </div>
  );
};

export default ProductList;