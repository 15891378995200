import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLayout from './component/AdminLayout';
import LoginPage from './component/login/LoginPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin/*" element={<AdminLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
