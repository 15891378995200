import { useEffect, useState } from 'react';
import './CategoryForm.css';
import { API_ENDPOINTS_MENU } from '../../util/apiConfig';
import axios from 'axios';

const CategoryForm = ({ updateId, categories }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    parentId: '',
    sortOrder: '',
    isActive: true,
    isVisible: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    image: null,
    icon: null,
    translations: []
  });

  const [errors, setErrors] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState(['en']);
  const [existingImages, setExistingImages] = useState({ image: '', icon: '' });
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  
  const supportedLanguages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'zh', name: 'Chinese' },
    { code: 'jp', name: 'Japanese' },
    { code: 'tw', name: 'Traditional Chinese' },
    { code: 'cn', name: 'Simplified Chinese' },
    { code: 'pe', name: 'Peruvian Spanish' }
  ];
  
  useEffect(() => {
    const fetchParentCategories = async () => {
      const filteredCategories = updateId 
        ? categories.filter(cat => cat.id !== Number(updateId))
        : categories;
      setParentCategories(filteredCategories);
    };

    fetchParentCategories();
  }, [updateId, categories]);
  
  useEffect(() => {
    const fetchCategoryData = async () => {
      if (!updateId) return;
      
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_ENDPOINTS_MENU.ADD_MENU}/${updateId}`);
        const categoryData = response.data;

        setFormData(prevData => ({
          ...prevData,
          name: categoryData.name || '',
          description: categoryData.description || '',
          parentId: categoryData.parentId || '',
          sortOrder: categoryData.sortOrder || '',
          isActive: categoryData.isActive ?? true,
          isVisible: categoryData.isVisible ?? true,
          metaTitle: categoryData.metaTitle || '',
          metaDescription: categoryData.metaDescription || '',
          metaKeywords: categoryData.metaKeywords || '',
        }));

        setExistingImages({
          image: categoryData.imageUrl || '',
          icon: categoryData.iconUrl || ''
        });

        if (categoryData.categoryMLDTO?.length > 0) {
          const translations = categoryData.categoryMLDTO.map(trans => ({
            id: trans.id,
            categoryId: trans.categoryId,
            languageCd: trans.languageCd,
            name: trans.name,
            description: trans.description
          }));

          const translationLanguages = translations.map(t => t.languageCd);
          setSelectedLanguages(['en', ...translationLanguages.filter(lang => lang !== 'en')]);
          setFormData(prevData => ({
            ...prevData,
            translations: translations
          }));
        }
        
        if (categoryData.parentId) {
          const parentCategory = parentCategories.find(cat => cat.id === categoryData.parentId);
          setSelectedParentCategory(parentCategory || null);
        }
      } catch (error) {
        console.error('Error fetching category:', error);
        setErrors({ fetch: 'Failed to load category data' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, [updateId, parentCategories]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }

    if (!updateId && !formData.image && !existingImages.image) {
      newErrors.image = 'Category image is required';
    }

    selectedLanguages.forEach((lang) => {
      if (lang !== 'en') {
        const translation = formData.translations.find(t => t.languageCd === lang);
        if (!translation?.name?.trim()) {
          newErrors[`${lang}_name`] = `Name in ${
            supportedLanguages.find(l => l.code === lang)?.name || lang
          } is required`;
        }
        if (!translation?.description?.trim()) {
          newErrors[`${lang}_description`] = `Description in ${
            supportedLanguages.find(l => l.code === lang)?.name || lang
          } is required`;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createPayload = () => {
    const formPayload = new FormData();
    
    if (updateId) {
      const updateRequest = {
        name: formData.name,
        description: formData.description,
        parentId: formData.parentId ? Number(formData.parentId) : null,
        sortOrder: formData.sortOrder ? Number(formData.sortOrder) : null,
        isActive: formData.isActive,
        isVisible: formData.isVisible,
        metaTitle: formData.metaTitle,
        metaDescription: formData.metaDescription,
        metaKeywords: formData.metaKeywords,
        categoryMLDTO: formData.translations.map(translation => ({
          id: translation.id || null,
          categoryId: translation.categoryId || null,
          languageCd: translation.languageCd,
          name: translation.name,
          description: translation.description
        }))
      };
      
      formPayload.append('request', new Blob([JSON.stringify(updateRequest)], {
        type: 'application/json'
      }));
    } else {
      const createRequest = {
        name: formData.name,
        description: formData.description,
        parentId: formData.parentId ? Number(formData.parentId) : null,
        sortOrder: formData.sortOrder ? Number(formData.sortOrder) : null,
        isActive: formData.isActive,
        isVisible: formData.isVisible,
        metaTitle: formData.metaTitle,
        metaDescription: formData.metaDescription,
        metaKeywords: formData.metaKeywords,
        categorCreateMLRequest: formData.translations.map(translation => ({
          languageCd: translation.languageCd,
          name: translation.name,
          description: translation.description
        }))
      };

      formPayload.append('request', new Blob([JSON.stringify(createRequest)], {
        type: 'application/json'
      }));
    }

    // Add files only if they're new or changed
    if (formData.image) {
      formPayload.append('image', formData.image);
    }
    if (formData.icon) {
      formPayload.append('icon', formData.icon);
    }

    return formPayload;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      // Scroll to the first error
      const firstError = document.querySelector('.error-message');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    setIsLoading(true);
    
    try {
      const formPayload = createPayload();
      const url = updateId 
        ? `${API_ENDPOINTS_MENU.ADD_MENU}/${updateId}`
        : API_ENDPOINTS_MENU.ADD_MENU;
      
      const method = updateId ? 'put' : 'post';
      
      const response = await axios[method](url, formPayload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.status !== 200) {
        throw new Error('Operation failed');
      }

      // Success notification could be added here
      alert(`Category successfully ${updateId ? 'updated' : 'created'}`);
      
      // Reset form or redirect could be added here
      
    } catch (error) {
      console.error('Error:', error);
      setErrors({ 
        submit: `Failed to ${updateId ? 'update' : 'create'} category. Please try again.` 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLanguageToggle = (langCode) => {
    setSelectedLanguages(prev => {
      if (prev.includes(langCode)) {
        // Don't allow removing English (default language)
        if (langCode === 'en') return prev;
        
        setFormData(prevData => ({
          ...prevData,
          translations: prevData.translations.filter(t => t.languageCd !== langCode)
        }));
        return prev.filter(l => l !== langCode);
      } else {
        setFormData(prevData => ({
          ...prevData,
          translations: [
            ...prevData.translations,
            { 
              languageCd: langCode, 
              name: '', 
              description: '',
              id: null,
              categoryId: updateId ? Number(updateId) : null
            }
          ]
        }));
        return [...prev, langCode];
      }
    });
  };

  const handleTranslationChange = (langCode, field, value) => {
    setFormData(prevData => ({
      ...prevData,
      translations: prevData.translations.map(t => 
        t.languageCd === langCode 
          ? { ...t, [field]: value }
          : t
      )
    }));
  };
  
  const handleParentCategoryChange = (e) => {
    const selectedId = e.target.value;
    const selected = selectedId ? parentCategories.find(cat => cat.id === Number(selectedId)) : null;
    setSelectedParentCategory(selected);
    setFormData(prev => ({
      ...prev,
      parentId: selectedId
    }));
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({...formData, [fieldName]: file});
      // Clear any existing errors for this field
      if (errors[fieldName]) {
        setErrors({...errors, [fieldName]: null});
      }
    }
  };

  const renderLanguageToggle = () => (
    <div className="form-section">
      <h3 className="section-title">Additional Languages</h3>
      <p className="language-note">English is the default language. Select additional languages to add translations.</p>
      <div className="language-toggles">
        {supportedLanguages.map((lang) => (
          <div key={lang.code} className={`language-toggle ${selectedLanguages.includes(lang.code) ? 'active' : ''}`}>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={selectedLanguages.includes(lang.code)}
                onChange={() => handleLanguageToggle(lang.code)}
                disabled={lang.code === 'en'} // English cannot be disabled
              />
              <span className="toggle-slider"></span>
            </label>
            <span className="language-name">{lang.name}</span>
            {lang.code === 'en' && <span className="default-badge">Default</span>}
          </div>
        ))}
      </div>
    </div>
  );

  const renderParentCategoryDropdown = () => (
    <div className="form-group">
      <label htmlFor="parentId">Parent Category</label>
      <select
        id="parentId"
        value={formData.parentId}
        onChange={handleParentCategoryChange}
        className="form-select"
      >
        <option value="">None (Top Level Category)</option>
        {parentCategories.map(category => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      {selectedParentCategory && (
        <p className="selected-parent-info">
          Selected parent: {selectedParentCategory.name}
        </p>
      )}
      {errors.parentCategories && (
        <p className="error-message">{errors.parentCategories}</p>
      )}
    </div>
  );

  const renderFileInput = (id, label, accept, onChange, errorKey, existingUrl) => (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <div className="file-input-container">
        <input
          type="file"
          id={id}
          accept={accept}
          onChange={onChange}
          className={errors[errorKey] ? 'error' : ''}
        />
        {existingUrl && (
          <div className="file-preview">
            <span className="file-label">Current file:</span>
            <span className="file-name">{existingUrl.split('/').pop()}</span>
          </div>
        )}
      </div>
      {errors[errorKey] && <p className="error-message">{errors[errorKey]}</p>}
    </div>
  );

  if (isLoading) {
    return <div className="loading">Loading category data...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="category-form">
      <div className="form-card">
        <div className="form-header">
          <h2>{updateId ? 'Update Category' : 'Create New Category'}</h2>
        </div>
        <div className="form-content">
          {/* Default Language Section */}
          <div className="form-section">
            <h3 className="section-title">English (Default)</h3>
            
            <div className="form-group">
              <label htmlFor="name">Name <span className="required">*</span></label>
              <input
                id="name"
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                className={errors.name ? 'error' : ''}
                placeholder="Enter category name"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="description">Description <span className="required">*</span></label>
              <textarea
                id="description"
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                className={errors.description ? 'error' : ''}
                placeholder="Enter category description"
              />
              {errors.description && <p className="error-message">{errors.description}</p>}
            </div>
          </div>

          {/* Language Selection */}
          {renderLanguageToggle()}

          {/* Translation Forms */}
          {selectedLanguages
            .filter(lang => lang !== 'en')
            .map((langCode) => {
              const lang = supportedLanguages.find(l => l.code === langCode);
              const translation = formData.translations.find(t => t.languageCd === langCode) || {};
              
              return (
                <div key={langCode} className="form-section translation-section">
                  <div className="translation-header">
                    <h3 className="section-title">{lang.name} Translation</h3>
                    <button 
                      type="button" 
                      className="remove-translation-btn"
                      onClick={() => handleLanguageToggle(langCode)}
                    >
                      Remove
                    </button>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor={`${langCode}_name`}>Name <span className="required">*</span></label>
                    <input
                      type="text"
                      id={`${langCode}_name`}
                      value={translation.name || ''}
                      onChange={(e) => handleTranslationChange(langCode, 'name', e.target.value)}
                      className={errors[`${langCode}_name`] ? 'error' : ''}
                      placeholder={`${lang.name} name`}
                    />
                    {errors[`${langCode}_name`] && (
                      <p className="error-message">{errors[`${langCode}_name`]}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor={`${langCode}_description`}>Description <span className="required">*</span></label>
                    <textarea
                      id={`${langCode}_description`}
                      value={translation.description || ''}
                      onChange={(e) => handleTranslationChange(langCode, 'description', e.target.value)}
                      className={errors[`${langCode}_description`] ? 'error' : ''}
                      placeholder={`${lang.name} description`}
                    />
                    {errors[`${langCode}_description`] && (
                      <p className="error-message">{errors[`${langCode}_description`]}</p>
                    )}
                  </div>
                </div>
              );
            })}

          {/* Category Settings */}
          <div className="form-section">
            <h3 className="section-title">Category Settings</h3>
            
            {renderParentCategoryDropdown()}

            <div className="form-group">
              <label htmlFor="sortOrder">Sort Order</label>
              <input
                type="number"
                id="sortOrder"
                value={formData.sortOrder}
                onChange={(e) => setFormData({...formData, sortOrder: e.target.value})}
                placeholder="Enter display order (lower numbers appear first)"
              />
            </div>

            <div className="switches">
              <div className="switch-group">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={formData.isActive}
                    onChange={(e) => setFormData({...formData, isActive: e.target.checked})}
                  />
                  <span className="toggle-slider"></span>
                </label>
                <span>Active</span>
              </div>

              <div className="switch-group">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={formData.isVisible}
                    onChange={(e) => setFormData({...formData, isVisible: e.target.checked})}
                  />
                  <span className="toggle-slider"></span>
                </label>
                <span>Visible</span>
              </div>
            </div>
          </div>

          {/* Meta Information */}
          <div className="form-section">
            <h3 className="section-title">Meta Information</h3>
            
            <div className="form-group">
              <label htmlFor="metaTitle">Meta Title</label>
              <input
                type="text"
                id="metaTitle"
                value={formData.metaTitle}
                onChange={(e) => setFormData({...formData, metaTitle: e.target.value})}
                placeholder="SEO meta title (appears in browser tab)"
              />
            </div>

            <div className="form-group">
              <label htmlFor="metaDescription">Meta Description</label>
              <textarea
                id="metaDescription"
                value={formData.metaDescription}
                onChange={(e) => setFormData({...formData, metaDescription: e.target.value})}
                placeholder="SEO meta description (appears in search results)"
              />
            </div>

            <div className="form-group">
              <label htmlFor="metaKeywords">Meta Keywords</label>
              <input
                type="text"
                id="metaKeywords"
                value={formData.metaKeywords}
                onChange={(e) => setFormData({...formData, metaKeywords: e.target.value})}
                placeholder="Comma-separated keywords for SEO"
              />
            </div>
          </div>

          {/* Image Uploads */}
          <div className="form-section">
            <h3 className="section-title">Images</h3>
            
            {renderFileInput(
              "image", 
              "Category Image" + ((!updateId && !existingImages.image) ? " *" : ""), 
              "image/*", 
              (e) => handleFileChange(e, 'image'),
              "image",
              existingImages.image
            )}
            
            {renderFileInput(
              "icon", 
              "Category Icon", 
              "image/*", 
              (e) => handleFileChange(e, 'icon'),
              "icon",
              existingImages.icon
            )}
          </div>

          {/* Error Message */}
          {errors.submit && (
            <div className="error-alert">
              <span className="error-icon">⚠</span>
              <p>{errors.submit}</p>
            </div>
          )}

          {/* Submit Button */}
          <button type="submit" className="submit-button" disabled={isLoading}>
            {updateId ? 'Update Category' : 'Create Category'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CategoryForm;