import React, { useState, useRef, useEffect } from 'react';
import { Check, X, ChevronDown } from 'lucide-react';

const MultiSelectDropdown = ({ 
  label, 
  placeholder,
  options, 
  selectedValues, 
  onChange,
  isLoading = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle an option's selection
  const toggleOption = (value) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter(item => item !== value)
      : [...selectedValues, value];
    
    onChange(updatedValues);
  };

  // Remove a selected option
  const removeOption = (value, e) => {
    e.stopPropagation();
    onChange(selectedValues.filter(item => item !== value));
  };

  return (
    <div className="form-group chip-input-container" ref={dropdownRef}>
      <label>{label}</label>
      <div 
        className={`chip-input-wrapper dropdown-wrapper ${isOpen ? 'focused' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="chips-container">
          {selectedValues.length > 0 ? (
            selectedValues.map((value, index) => (
              <div key={index} className="chip">
                <span>{value}</span>
                <button
                  type="button"
                  className="chip-remove"
                  onClick={(e) => removeOption(value, e)}
                >
                  <X size={12} />
                </button>
              </div>
            ))
          ) : (
            <div className="placeholder-text">{placeholder}</div>
          )}
        </div>
        <div className="dropdown-indicator">
          <ChevronDown size={16} />
        </div>
        
        {isOpen && (
          <div className="dropdown-list">
            {isLoading ? (
              <div className="dropdown-loading">Loading...</div>
            ) : options.length === 0 ? (
              <div className="dropdown-empty">No options available</div>
            ) : (
              options.map((option, index) => (
                <div 
                  key={index} 
                  className={`dropdown-item ${selectedValues.includes(option.value) ? 'selected' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOption(option.value);
                  }}
                >
                  <div className="checkbox">
                    {selectedValues.includes(option.value) && <Check size={14} />}
                  </div>
                  <span>{option.label}</span>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;