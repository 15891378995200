import { useState, useRef } from 'react';
import { Plus, Trash2, Image, X } from 'lucide-react';
import './ProductGallery.css';

// This component will be integrated into the existing ProductEditForm
const ProductGallery = ({ images = [], onChange }) => {
  const [selectedImages, setSelectedImages] = useState(images);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
  
    const newPreviewImages = files.map(file => ({
      id: `new-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      file, // Store the actual file object
      url: URL.createObjectURL(file),
      isNew: true
    }));
  
    setPreviewImages([...previewImages, ...newPreviewImages]);
    // Pass the actual file objects to parent
    onChange([...selectedImages, ...newPreviewImages]);
  };

  // Open file dialog
  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  // Remove an image
  const handleRemoveImage = (indexToRemove) => {
    const isPreviewImage = indexToRemove >= selectedImages.length;
    
    if (isPreviewImage) {
      const previewIndex = indexToRemove - selectedImages.length;
      // Release object URL to prevent memory leaks
      URL.revokeObjectURL(previewImages[previewIndex].url);
      
      const updatedPreviews = previewImages.filter((_, index) => index !== previewIndex);
      setPreviewImages(updatedPreviews);
      
      // Update parent component with the combined array excluding the removed preview
      onChange([...selectedImages, ...updatedPreviews.map(img => img.id)]);
    } else {
      // Remove an existing image
      const updatedImages = selectedImages.filter((_, index) => index !== indexToRemove);
      setSelectedImages(updatedImages);
      
      // Update parent component
      onChange([...updatedImages, ...previewImages.map(img => img.id)]);
    }
  };

  return (
    <div className="product-section">
      <h3 className="section-title">Product Gallery</h3>
      
      <div className="image-gallery-container">
        <div className="image-grid">
          {/* Existing images */}
          {selectedImages.map((image, index) => (
            <div key={`existing-${index}`} className="image-item">
              <div className="image-wrapper">
                <img src={image.url || `/product-images/${image}`} alt={`Product ${index}`} />
                <button 
                  type="button" 
                  className="remove-image-btn"
                  onClick={() => handleRemoveImage(index)}
                >
                  <X size={16} />
                </button>
              </div>
              <span className="image-name">{image.filename || `Image ${index + 1}`}</span>
            </div>
          ))}
          
          {/* New preview images */}
          {previewImages.map((preview, index) => (
            <div key={`preview-${index}`} className="image-item">
              <div className="image-wrapper">
                <img src={preview.url} alt={`New upload ${index}`} />
                <button 
                  type="button" 
                  className="remove-image-btn"
                  onClick={() => handleRemoveImage(selectedImages.length + index)}
                >
                  <X size={16} />
                </button>
              </div>
              <span className="image-name">{preview.file.name}</span>
            </div>
          ))}
          
          {/* Add image button */}
          <div className="add-image-btn-container">
            <button 
              type="button" 
              className="add-image-btn"
              onClick={handleAddImageClick}
            >
              <Plus size={24} />
              <span>Add Image</span>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;