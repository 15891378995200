const API_BASE_URL = 'https://api.mememall.tv/api'; 
// const API_BASE_URL = 'http://localhost:8080/api'; 
const API_BASE_URL_AUTH = 'https://discovery.mememall.tv/api'; 

export const API_ENDPOINTS = {
  PRODUCTS: `${API_BASE_URL}/v1/products/filter`,
  PRODUCT_BY_ID:`${API_BASE_URL}/v1/products/update`,
  PRODUCT_UPDATE:`${API_BASE_URL}/v1/products`,
  ORDERS_ALL:`${API_BASE_URL}/v1/orders`,
  ORDERS_UPDATE:`${API_BASE_URL}/v1/orders/update/offer/`,
  COLLECTION:`${API_BASE_URL}/v1/collection`,
  BRANDS:`${API_BASE_URL}/v1/brands`,
  OCCATIONS:`${API_BASE_URL}/v1/occation`,
  DASHBOARD:`${API_BASE_URL}/v1/dashboard`
};



export const API_ENDPOINTS_MENU = {
  MENU: `${API_BASE_URL}/v1/categories/tree/en`,
  ADD_MENU:`${API_BASE_URL}/v1/categories`,
};

export const API_ENDPOINTS_AUTH = {
  CUSTOMERS: `${API_BASE_URL_AUTH}/users/all/`,
  PLAN: `${API_BASE_URL_AUTH}/subscriptions/plan`,
  LOGIN:`${API_BASE_URL}/v1/vendors/login`,
  VENDORS:`${API_BASE_URL}/v1/vendors?`,
};
