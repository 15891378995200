// LoginPage.jsx
import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import { API_ENDPOINTS_AUTH } from '../../util/apiConfig';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const[vendors,setVendor]=useState(localStorage.getItem("user"));
  useEffect(()=>{
    if(vendors){
      navigate("/admin");
    }
  })
 const  navigate=useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(API_ENDPOINTS_AUTH.LOGIN, { email, password });
        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));
            navigate("/admin");
        }
    } catch (error) {
        console.error("Login failed:", error.response?.data?.message || error.message);
        alert("Login failed! Please check your credentials.");
    }
};

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h1>Admin Login</h1>
          <p>Enter your credentials to access the admin panel</p>
        </div>
        
        <form className="login-form" onSubmit={handleSubmit}>
          {error && <div className="error-message">{error}</div>}
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="admin@example.com"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
              required
            />
          </div>

          <button type="submit" className="login-button">
            Sign in
          </button>
        </form>

        <div className="login-footer">
          <a href="#" className="forgot-password">
            Forgot your password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;