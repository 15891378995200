import React, { useEffect, useState } from 'react';
import { Search, Plus, Edit2, Trash2, Filter, ArrowUpDown, Mail, PhoneCall } from 'lucide-react';
import './CustomerList.css';
import { API_ENDPOINTS_AUTH } from '../util/apiConfig';
import axios from 'axios';
const CustomerList = () => {
  // Sample customer data
  const initialCustomers = [
    { 
      id: 1, 
      name: 'John Doe', 
      email: 'john@example.com', 
      phone: '+1 234-567-8901',
      joinDate: '2024-01-15',
      orders: 12,
      status: 'Active',
      totalSpent: 1299.99
    },
    { 
      id: 2, 
      name: 'Jane Smith', 
      email: 'jane@example.com', 
      phone: '+1 234-567-8902',
      joinDate: '2024-02-01',
      orders: 5,
      status: 'Active',
      totalSpent: 499.99
    },
    { 
      id: 3, 
      name: 'Mike Johnson', 
      email: 'mike@example.com', 
      phone: '+1 234-567-8903',
      joinDate: '2024-01-20',
      orders: 0,
      status: 'Inactive',
      totalSpent: 0
    },
    { 
      id: 4, 
      name: 'Sarah Wilson', 
      email: 'sarah@example.com', 
      phone: '+1 234-567-8904',
      joinDate: '2024-02-10',
      orders: 8,
      status: 'Active',
      totalSpent: 899.99
    },
  ];

  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  
  useEffect(()=>{
    const getUsers=async ()=>{
       const data=await axios.get(API_ENDPOINTS_AUTH.CUSTOMERS+0+'/'+10).then((response)=>{
        setCustomers(response.data['content'])
       })
    }
    getUsers();
  },[])
  // Filter customers based on search term and status
  const filteredCustomers = customers.filter(customer => {
    const matchesSearch = 
    customer.username!=null?customer.username.toLowerCase().includes(searchTerm.toLowerCase()):'' ||
      customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone.includes(searchTerm);
    const matchesStatus = filterStatus === 'All' || customer.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  // Sort customers
  const sortCustomers = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedCustomers = () => {
    if (!sortConfig.key) return filteredCustomers;

    return [...filteredCustomers].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      setCustomers(customers.filter(customer => customer.id !== id));
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case true:
        return 'status-active';
      case false:
        return 'status-inactive';
      default:
        return '';
    }
  };
  const getSubClass = (status) => {
    switch (status) {
      case "Silver":
        return 'status-silver';
      case "Gold":
        return 'status-gold';
      case "Diamond":
        return 'status-diamond';
      default:
        return '';
    }
  };
  const formatDate = (dateString) => {
    const formattedString = dateString.replace(" ", "T");
    return new Date(formattedString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

  return (
    <div className="customer-list">
      {/* Header Actions */}
      <div className="list-header">
        <h2></h2>
        <button className="btn-primary">
          <Plus size={16} />
          Add Customer
        </button>
      </div>

      {/* Filters */}
      <div className="filters">
        <div className="search-box">
          <Search size={20} />
          <input
            type="text"
            placeholder="Search customers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-box">
          <Filter size={20} />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="All">All Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
      </div>

      {/* Customers Table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th onClick={() => sortCustomers('name')}>
                Customer Name
                <ArrowUpDown size={16} />
              </th>
              <th>Contact</th>
              <th onClick={() => sortCustomers('joinDate')}>
                Join Date
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortCustomers('orders')}>
                Subscription
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortCustomers('totalSpent')}>
                Total Referral
                <ArrowUpDown size={16} />
              </th>
              <th onClick={() => sortCustomers('status')}>
                Status
                <ArrowUpDown size={16} />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {getSortedCustomers().map((customer) => (
              <tr key={customer.id}>
                <td>
                  <div className="customer-name">{customer.username}</div>
                </td>
                <td>
                  <div className="contact-info">
                    <div className="contact-item">
                      <Mail size={14} />
                      {customer.email}
                    </div>
                    <div className="contact-item">
                      <PhoneCall size={14} />
                      {customer.phone}
                    </div>
                  </div>
                </td>
                <td>{formatDate(customer.createdDate)}</td> 
                <td>
                <span className={`status-badge ${getSubClass(customer.subscriptionType)}`}>
                {customer.subscriptionType}
                  </span>
                </td>
                <td>{customer.totalNumberReferrals}</td>
                <td>
                  <span className={`status-badge ${getStatusClass(customer.active)}`}>
                    {customer.active?'Active':'In Active'}
                  </span>
                </td>
                <td>
                  <div className="action-buttons">
                    <button className="btn-icon" onClick={() => setSelectedCustomer(customer)}>
                      <Edit2 size={16} />
                    </button>
                    <button className="btn-icon delete" onClick={() => handleDelete(customer.id)}>
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerList;