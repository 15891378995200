import { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Truck, Package, DollarSign, ChevronLeft, ChevronRight } from 'lucide-react';
import './OrderList.css';
import { API_ENDPOINTS } from '../../util/apiConfig';
import axios from 'axios';

const OrderList = () => {
  const [orderData, setOrderData] = useState({
    content: [],
    pageable: {
      pageNumber: 0,
      pageSize: 5
    },
    totalPages: 0,
    totalElements: 0
  });
  
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  
  // User role would come from auth context in a real app
  // This is now just internal state, not exposed to the user
  const [userRole, setUserRole] = useState('admin'); 
  const [vendorId, setVendorId] = useState('123'); // This would come from auth context
  
  const statusOptions = [
    "pending",
    "processing",
    "shipped",
    "delivered",
    "cancelled"
  ];

  const fetchOrders = async (page = 0, size = 5) => { 
    try {
      // For admin, fetch all orders
      // For vendor, fetch only orders with their items
      const endpoint = userRole === 'admin' 
        ? `${API_ENDPOINTS.ORDERS_ALL}?page=${page}&size=${size}`
        : `${API_ENDPOINTS.ORDERS_ALL}/${vendorId}?page=${page}&size=${size}`;
        
      const response = await axios.get(endpoint);
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchOrders();
  }, [userRole, vendorId]);

  const handleOrderStatusChange = async (orderId, newStatus) => {
    try {
      // Update order status - admin only
      if (userRole === 'admin') {
        // API call to update order status
        await axios.post(`${API_ENDPOINTS.ORDERS_UPDATE}order`, { 
          itemId:orderId, 
          status: newStatus,
          updatedBy:'admin' 
        });
        
        // Update local state
        setOrderData(prevData => {
          const updatedContent = prevData.content.map(order => 
            order.id === orderId ? { ...order, status: newStatus } : order
          );
          
          return {
            ...prevData,
            content: updatedContent
          };
        });
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };
  
  const handleItemStatusChange = async (orderId, itemIds, newStatus) => {
    try {
      // Update item status - vendor only
      if (userRole === 'vendor' && itemIds.length > 0) {
        // API call to update item status
        await axios.post(`${API_ENDPOINTS.ORDERS_UPDATE}item`, { 
          itemId:itemIds, 
          status: newStatus,
          updatedBy:vendorId 
        });
        
        // Update local state
        setOrderData(prevData => {
          const updatedContent = prevData.content.map(order => {
            if (order.id === orderId) {
              const updatedItems = order.orderItems.map(item => 
                itemIds.includes(item.id) ? { ...item, status: newStatus } : item
              );
              return { ...order, orderItems: updatedItems };
            }
            return order;
          });
          
          return {
            ...prevData,
            content: updatedContent
          };
        });
        
        // Clear selection after update
        setSelectedItems([]);
      }
    } catch (error) {
      console.error("Error updating item status:", error);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      pending: "status-pending",
      processing: "status-processing",
      shipped: "status-shipped",
      delivered: "status-delivered",
      cancelled: "status-cancelled"
    };
    return colors[status?.toLowerCase()] || "status-pending";
  };
  
  const handlePageChange = (newPage) => {
    fetchOrders(newPage, orderData.pageable.pageSize);
  };
  
  const calculateOrderTotal = (orderItems) => {
    return orderItems.reduce((total, item) => total + (item.subTotal || 0), 0).toFixed(2);
  };
  
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };
  
  const toggleItemSelection = (itemId) => {
    setSelectedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId) 
        : [...prev, itemId]
    );
  };
  
  return (
    <div className="orders-container">
      {orderData.content.map(order => (
        <div key={order.id} className="order-card">
          <div className="order-header">
            <div className="order-basic-info">
              <h3>Order #{order.orderCode}</h3>
              <span className={`status-badge ${getStatusColor(order.status || 'pending')}`}>
                {(order.status || 'pending').toLowerCase()}
              </span>
            </div>
            <div className="order-summary">
              <span className="order-date">{formatDate(order.createdTime)}</span>
              <span className="order-amount">${order.total || calculateOrderTotal(order.orderItems)}</span>
              <button 
                className="expand-button"
                onClick={() => setExpandedOrder(
                  expandedOrder === order.id ? null : order.id
                )}
              >
                {expandedOrder === order.id ? 
                  <ChevronUp className="icon" /> : 
                  <ChevronDown className="icon" />
                }
              </button>
            </div>
          </div>

          {expandedOrder === order.id && (
            <div className="order-details">
              <div className="details-grid">
                <div className="detail-section">
                  <h4><Package className="icon" /> Items</h4>
                  <table className="items-table">
                    <thead>
                      <tr>
                        {userRole === 'vendor' && <th>Select</th>}
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderItems.map(item => (
                        <tr key={item.id}>
                          {userRole === 'vendor' && (
                            <td>
                              <input 
                                type="checkbox" 
                                checked={selectedItems.includes(item.id)}
                                onChange={() => toggleItemSelection(item.id)}
                              />
                            </td>
                          )}
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                          <td>${(item.sellingPrice || 0).toFixed(2)}</td>
                          <td>${(item.subTotal || 0).toFixed(2)}</td>
                          <td>
                            <span className={`status-badge ${getStatusColor(item.status || 'pending')}`}>
                              {(item.status || 'pending').toLowerCase()}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  
                  {/* Vendor-specific controls for selected items */}
                  {userRole === 'vendor' && selectedItems.length > 0 && (
                    <div className="item-status-update">
                      <select 
                        className="status-select"
                        defaultValue=""
                      >
                        <option value="" disabled>Select status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                          </option>
                        ))}
                      </select>
                      <button 
                        className="update-status-button"
                        onClick={(e) => {
                          const newStatus = e.target.previousSibling.value;
                          if (newStatus) {
                            handleItemStatusChange(order.id, selectedItems, newStatus);
                          }
                        }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>

                <div className="detail-section">
                  <h4><Truck className="icon" /> Shipping</h4>
                  <div className="shipping-info">
                    <p>{order.flatNo}</p>
                    <p>{order.addrssLine1}, {order.addrsesLine2}</p>
                    <p>{order.zipocode}, {order.country}</p>
                    <p>Phone: {order.phone}</p>
                    <p>Email: {order.email}</p>
                  </div>
                </div>

                <div className="detail-section">
                  <h4><DollarSign className="icon" /> Payment</h4>
                  <div className="payment-info">
                    <p>Transaction: {order.tnxNumber || 'N/A'}</p>
                    <p>Total: ${order.total || calculateOrderTotal(order.orderItems)}</p>
                  </div>
                </div>

                {/* Admin-only order status update */}
                {userRole === 'admin' && (
                  <div className="detail-section">
                    <h4>Update Order Status</h4>
                    <select 
                      value={order.status || 'pending'}
                      onChange={(e) => handleOrderStatusChange(order.id, e.target.value)}
                      className="status-select"
                    >
                      {statusOptions.map(status => (
                        <option key={status} value={status}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
      
      {/* Pagination */}
      {orderData.totalPages > 1 && (
        <div className="pagination">
          <button 
            onClick={() => handlePageChange(orderData.pageable.pageNumber - 1)}
            disabled={orderData.first}
            className="pagination-button"
          >
            <ChevronLeft className="icon" />
          </button>
          
          <span className="pagination-info">
            Page {orderData.pageable.pageNumber + 1} of {orderData.totalPages}
          </span>
          
          <button 
            onClick={() => handlePageChange(orderData.pageable.pageNumber + 1)}
            disabled={orderData.last}
            className="pagination-button"
          >
            <ChevronRight className="icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderList;